import React from 'react';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { Tooltip } from '@appkit4/react-components/tooltip';
import './index.scss';
import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import ClipboardJS from 'clipboard';

const SampleCode = (props: any) => {
  const { codeContent } = props;
  const codeRef = React.useRef<any>();
  const [isExpand, setIsExpand] = React.useState(0);
  const [tipContent, setTipContent] = React.useState('Copy code');
  // const [codeContent, setCodeContent] =
  //   React.useState(`<ap-tabset [type]="'filled'" [(activeIndex)]="activeIndex" [overflow]="isOverflowTab">
  //   <ap-tab *ngFor="let tab of tabs; let i = index" [label]="tab.label" [disabled]="tab.disabled">
  //     <!-- custom content here -->
  //   </ap-tab>
  // </ap-tabset>`);
  const onSampleCodeTabChange = () => {
    console.log('--onSampleCodeTabChange--:');
  };

  React.useEffect(() => {
    Prism.highlightAll();

    let clipboard: any;
    if (codeRef && codeRef.current) {
      clipboard = new ClipboardJS('.sample-code-copy-icon', {
        text: () => codeContent,
      });

      clipboard.on('success', function (e: any) {
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);
        // setTipContent('Copied');
        setTipContent('Copy code');

        e.clearSelection();
      });

      clipboard.on('error', function (e: any) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
      });
    }

    return () => {
      clipboard.destroy();
    };
  }, [codeContent]);

  const issueCodeSnippetCopy = () => {};

  return (
    <div className="sample-code">
      <div className="sample-code-tabs"></div>
      <div className="simple-code-codePart">
        <div className="simple-code-codePart-cpIcon-part">
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            id="tooltipSamplePage-cpbtn"
            appendAfterTarget={false}
            hideTooltipOnBlur={true}
            content={tipContent}
            className="sample-code-copy-icon"
          >
            <span
              className="Appkit4-icon icon-copy-outline sample-code-copy-icon"
              onClick={issueCodeSnippetCopy} tabIndex={0}
            ></span>
          </Tooltip>
        </div>
        <div className={`sample-code-single-wrapper ${isExpand ? 'collapse' : ''}`}>
          <pre className="sample-code-wrapper language-js line-numbers" tabIndex={-1}>
            <code ref={codeRef}>{codeContent}</code>
          </pre>
        </div>
      </div>
      {/* <div className="simple-code-expandBtn">
        <Tooltip
          trigger="hover"
          position="top"
          distance={4}
          id="tooltipSamplePage-expand"
          appendAfterTarget={false}
          hideTooltipOnBlur={true}
          content={'Copy code'}
        >
          <span className="Appkit4-icon icon-expand-chevron-outline"></span>
        </Tooltip>
      </div> */}
    </div>
  );
};
export default SampleCode;
