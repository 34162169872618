import React, { useEffect, useState } from 'react';
import './index.scss';
import { Avatar } from '@appkit4/react-components/avatar';
import { getNameAbbrev, getRoleValue, capitalizeFirstLetter, encryptData, decryptData, getCookie, safelyParseJSON } from '@utils/common';
import { Select } from '@appkit4/react-components';
import { rolesArr, rolesBtn } from '../constant';
import { IRoleType } from '@components/Home/types';
import { productService } from '@services';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EntryContext } from '@layout/Entry';

const SelectedUser = (props: any) => {
  const { user: currentLoginUser } = React.useContext(EntryContext);
  const [modalDropdownDataValue, setModalDropdownDataValue] = React.useState<any>('');
  const [finalRoles, setFinalRoles] = React.useState<IRoleType[]>([]);
  const {
    user,
    setSelectedUsers,
    origin,
    selectedUsers,
    setShowManageAccessModalIssue,
    getIssueText,
    curLoginUserRole,
    uuid,
  } = props;

  const curLoginUser = currentLoginUser || safelyParseJSON(decryptData(getCookie('user')||'')!);

  const getRoleValue = (data: any[], value: string) => {
    return data?.filter((r: IRoleType) => r.value === value)[0]?.value;
  };

  const [finalUserInfo, setFinalUserInfo] = useState<any>(user);

  useEffect(() => {
    const namePart = user?.id?.split('@')[0];
    const firstName = capitalizeFirstLetter(user.firstName ? user.firstName : namePart?.split('.')[0]);
    const lastName = capitalizeFirstLetter(user.lastName ? user.lastName : namePart?.split('.')[1]);
    setFinalUserInfo({
      ...user,
      firstName,
      lastName,
      formattedName: user?.formattedName
        ? user.formattedName
        : `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`,
    });
  }, [user]);

  React.useEffect(() => {
    if (origin === 'settings') {
      const role = curLoginUserRole?.toLowerCase();
      if (role !== 'owner') {
        setFinalRoles(rolesBtn);
        setModalDropdownDataValue(getRoleValue(rolesBtn, user?.role));
      } else {
        setFinalRoles(rolesArr);
        setModalDropdownDataValue(getRoleValue(rolesArr, user?.role));
      }
    }
    if (origin === 'home') {
      setModalDropdownDataValue(getRoleValue(rolesBtn, user?.role));
      setFinalRoles(rolesBtn);
    }
  }, [curLoginUserRole, origin, uuid]);

  const nameAbbrev = React.useMemo(() => {
    return getNameAbbrev(finalUserInfo.firstName, finalUserInfo.lastName);
  }, [finalUserInfo]);

  const ownerUsrHandler = (latestUsers: any[], action: string) => {
    const ownerUsrACntAbove2handler = (ownerUser: any[]) => {
      if (ownerUser?.length >= 2) {
        const temps = [...latestUsers];

        for (let i = 0; i < ownerUser.length; i++) {
          const owner = ownerUser[i];
          for (let j = 0; j < temps.length; j++) {
            const t = temps[j];
            if (owner?.email === t?.email) {
              t.hasError = true;
            }
          }
        }
        setSelectedUsers(temps);
        setShowManageAccessModalIssue && setShowManageAccessModalIssue(true);
        getIssueText && getIssueText('Only one team member can be owner.');
      } else {
        setSelectedUsers(latestUsers?.map((p) => ({ ...p, hasError: false })));
        setShowManageAccessModalIssue && setShowManageAccessModalIssue(false);
        getIssueText && getIssueText('');
      }
    };

    const ownerUser = latestUsers?.filter((usr: any) => usr?.role === 'owner');
    if (action === 'roleChange') {
      ownerUsrACntAbove2handler(ownerUser);
    } else if (action === 'delete') {
      if (ownerUser?.length === 0) {
        setShowManageAccessModalIssue && setShowManageAccessModalIssue(true);
        setSelectedUsers((prevs: any[]) => prevs.map((prev) => ({ ...prev, hasError: prev.role === 'owner' })));
        getIssueText && getIssueText('Please  transfer “Owner” role to any other team member.');
      } else {
        ownerUsrACntAbove2handler(ownerUser);
      }
    }
  };

  const deleteProductUserFromFE = () => {
    if (origin === 'home') {
      setSelectedUsers &&
        setSelectedUsers((prevs: any[]) => {
          return prevs.filter((prev) => prev?.email !== user?.email);
        });
    } else if (origin === 'settings') {
      const resAfterDelete = selectedUsers?.filter((usr: any) => usr?.email !== user?.email);

      ownerUsrHandler(resAfterDelete, 'delete');
    }
  };

  const getLeftTwoContent = () => {
    const getDeleteIconEle = () => {
      if (curLoginUserRole !== 'owner') {
        if (user.isOwner) {
          return (
            <>
              <div className="selectUser-owner-empty"></div>
              <div className="selectUser-owner-text">
                <span className="selectUser-owner-text-span">Owner</span>
              </div>
            </>
          );
        }
        return (
          <div className="selectUser-deleteIcon" onClick={deleteProductUserFromFE}>
            <span className="Appkit4-icon icon-delete-outline"></span>
          </div>
        );
      }
      return (
        <>
          <div className="selectUser-deleteIcon" onClick={deleteProductUserFromFE}>
            <span className="Appkit4-icon icon-delete-outline"></span>
          </div>
        </>
      );
    };

    const roleSelectChange = (vals: any) => {
      const latestUsers = selectedUsers?.map((usr: any) => {
        if (usr?.email === user?.email) {
          return { ...usr, role: vals, hasError: false };
        }
        return { ...usr, hasError: false };
      });
      if (origin === 'home') {
        setSelectedUsers(latestUsers);
        return;
      }
      ownerUsrHandler(latestUsers, 'roleChange');
    };

    if (origin === 'home') {
      if (user?.email === curLoginUser?.id) {
        return (
          <>
            <div className="selectUser-owner-empty"></div>
            <div className="selectUser-owner-text">
              <span className="selectUser-owner-text-span">Owner</span>
            </div>
          </>
        );
      }
    }
    if (origin === 'settings') {
      if (selectedUsers?.length === 1) {
        return (
          <>
            <div className="selectUser-owner-empty"></div>
            <div className="selectUser-owner-text">
              <span className="selectUser-owner-text-span">Owner</span>
            </div>
          </>
        );
      }
      if (user?.role === 'owner' && curLoginUserRole !== 'owner') {
        return (
          <>
            <div className="selectUser-owner-empty"></div>
            <div className="selectUser-owner-text">
              <span className="selectUser-owner-text-span">Owner</span>
            </div>
          </>
        );
      }
    }

    return (
      <>
        <div className="selectUser-selectRole">
          <Select
            data={finalRoles}
            defaultValue={modalDropdownDataValue}
            value={modalDropdownDataValue}
            placeholder={modalDropdownDataValue ? 'Role' : 'Select Role'}
            onSelect={(vals) => {
              setShowManageAccessModalIssue && setShowManageAccessModalIssue(false);
              getIssueText && getIssueText('');
              setModalDropdownDataValue(vals);
              roleSelectChange(vals);
            }}
            dropdownRenderMode="portal"
            dropdownAlwaysDown={false}
            error={user?.hasError}
          ></Select>
        </div>
        {getDeleteIconEle()}
      </>
    );
  };

  return (
    <div className="selectUser">
      <div className="selectUser-avatar">
        <Avatar
          tabIndex={0}
          disabled={false}
          role="button"
          label={nameAbbrev}
          ariaLabel={`User Profile,${finalUserInfo?.firstName} ${finalUserInfo?.lastName}`}
        />
      </div>
      <div className="selectUser-userInfo">
        <span>
          {finalUserInfo?.formattedName}
          {[finalUserInfo?.email, finalUserInfo?.id].includes(curLoginUser?.id) ? '(you)' : ''}
        </span>
        <span title={finalUserInfo?.email}>{finalUserInfo?.email || finalUserInfo?.id}</span>
      </div>
      {getLeftTwoContent()}
    </div>
  );
};

export default SelectedUser;
