import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import './commonModal.scss';

const CommonModal = (props: any) => {
  const { visible = true, modalContent, setVisibleFunc, className = '', bodyStyle = {}, onClose, maskCloseable=true, onCancel } = props;
  return (
    <Modal
      visible={visible}
      maskCloseable={maskCloseable}
      title={modalContent.title}
      ariaLabel={modalContent.title}
      onCancel={() => {
        setVisibleFunc(false);
        onCancel?.();
      }}
      modalStyle={{ width: '33.75rem' }}
      // footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
      header={''}
      icons={''}
      footer={modalContent.footer}
      bodyStyle={Object.assign({ minHeight: '92px' }, bodyStyle)}
      className={className}
      // initialFocus={false}
      onClose={() => {
        onClose && onClose();
      }}
    >
      {modalContent.body}
    </Modal>
  );
};

export default CommonModal;
