import React, { memo } from 'react';
import { Footer } from '@appkit4/react-components';
import './footer.scss';
import { Link } from 'react-router-dom';

const FooterPage = memo(() => {
  const footerContent =
    '© 2024 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.';
  const footerType = 'links';
  // const footerType = 'text';
  const footerLinks = [
    // { name: 'Privacy policy', href: '#', target: '_blank' },
    // { name: 'Cookie notice', href: '#' },
    // { name: 'Terms and conditions', href: '#', target: '_self' },
    // { name: 'Customize cookie settings', href: '#', target: '_self' },
    {
      name: 'Terms and conditions',
      href: 'https://productcentral.products.pwc.com/us/en/legal/terms-of-use.html',
      target: '_blank',
    },
  ];
  // This is the function for customizing the link items of the footer
  const renderFooterItemFunc = (item: { name: string; href: string }, index: number) => (
    <div className="ap-footer-link" key={index}>
      <a className="ap-link" href={item.href}>
        {item.name}
      </a>
      <span className="ap-footer-divider">|</span>
    </div>
  );

  return (
    <div className={'footer-container'}>
      <Footer
        content={footerContent}
        type={footerType}
        links={footerLinks}
        className={'footer-container-footer'}
      ></Footer>
      {/* <div className="poweredByArea">
        <span>
          Powered by{' '}
          <Link to={'https://appkit.pwc.com/appkit4/content/landing'} className="pb-link" target="_blank">
            Appkit
          </Link>
        </span>
      </div> */}
    </div>
  );
});

export default FooterPage;
