export const filtersStatusPartData = [
  { name: 'Open issues', value: 'open', checked: false, disabled: false, belongsTo: 'status' },
  { name: 'Closed issues', value: 'close', checked: false, disabled: false, belongsTo: 'status' },
];

export const filtersSeverityData = [
  { name: 'Critical', value: 'critical', checked: false, disabled: false, belongsTo: 'severity' },
  { name: 'Serious', value: 'serious', checked: false, disabled: false, belongsTo: 'severity' },
  { name: 'Moderate', value: 'moderate', checked: false, disabled: false, belongsTo: 'severity' },
  { name: 'Minor', value: 'minor', checked: false, disabled: false, belongsTo: 'severity' },
];

export const filterEffortLevelData = [
  { name: 'Small', value: 'small', checked: false, disabled: false, belongsTo: 'effortlevel' },
  { name: 'Medium', value: 'medium', checked: false, disabled: false, belongsTo: 'effortlevel' },
  { name: 'Large', value: 'large', checked: false, disabled: false, belongsTo: 'effortlevel' },
  // { name: 'X-large', value: 'x-large', checked: false, disabled: false, belongsTo: 'effortlevel' },
];

export const filtersWcagData = [
  { name: 'ARIA', value: 'aria', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Color', value: 'color', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Forms', value: 'forms', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Keyboard', value: 'keyboard', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Language', value: 'language', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Link-Button', value: 'link-button', checked: false, disabled: false, belongsTo: 'wcagcategory' },// manual have auto no
  // { name: 'Name-Role-Value', value: 'name-role-value', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Name/Role/Value', value: 'name/role/value', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Page zoom', value: 'pagezoom', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Semantice', value: 'semantice', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Semantices', value: 'semantices', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Semantics', value: 'semantics', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  {
    name: 'Sensory and visual cues',
    value: 'sensoryandvisualcues',
    checked: false,
    disabled: false,
    belongsTo: 'wcagcategory',
  },
  { name: 'Structure', value: 'structure', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Tables', value: 'tables', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Text Alternative', value: 'textalternative', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Text Alternatives', value: 'textalternatives', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Text alternatives', value: 'textalternatives', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Text-alternative', value: 'text-alternative', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Time and Media', value: 'timeandmedia', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  // { name: 'Time-and-Media', value: 'time-and-media', checked: false, disabled: false, belongsTo: 'wcagcategory' },
  { name: 'Time and media', value: 'timeandmedia', checked: false, disabled: false, belongsTo: 'wcagcategory' },
];
