import { IRoleType } from '@components/Home/types';
import CryptoJS from 'crypto-js'; 
import xss from 'xss';

export const urlValidator = (value: string) => {
  if (!!value) {
    const url = value.trim();
    const commonUrlRegExp = new RegExp(
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:[a-z\u00a1-\uffff0-9\-]+)(?:\.[a-z\u00a1-\uffff0-9\-]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/,
    );
    const additionalRegExp1 = new RegExp(
      /*eslint no-useless-escape: "off"*/
      /^(((http|ftp|https):\/{2})?(([0-9a-z_-]+\.)+(aero|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mn|mn|mo|mp|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|nom|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ra|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw|arpa)(:[0-9]+)?((\/([~0-9a-zA-Z\#\+\%@\.\/_-]+))?(\?[0-9a-zA-Z\+\%@\/&\[\];=_-]+)?)?))\b/,
    );
    const additionalRegExp2 = new RegExp(/^((https?|ftp):\/\/)?(\-\.)?([^\s/?\.\#\-]+\.?)+(\/[^\s]*)?$/, 'i');
    const commonUrlValid = url.match(commonUrlRegExp);
    const additionalRegExp1Valid = url.match(additionalRegExp1);
    const additionalRegExp2Valid = url.match(additionalRegExp2);
    if (url.length > 2000) {
      return 'Exceeded maximum length 2000 strings.';
    }
    if (
      (!!commonUrlValid && !!additionalRegExp1Valid) ||
      (!commonUrlValid && !!additionalRegExp1Valid && !!additionalRegExp2Valid)
    ) {
      return true;
    }
    return 'Please provide a valid URL.';
  }
  return true;
};

export const getClickableLink = (link: string) => {
  if (!link) {
    return '';
  }
  return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
};

const isFocusableEle = (ele: any) => {
  const _role = ele.getAttribute('role');
  const _dataInTable = ele.getAttribute('data-in-table');
  const _dataControlBySelf = ele.getAttribute('data-cotrol-self') === 'true';
  const _res =
    (ele.onclick ||
      ele.tagName === 'A' ||
      ele.tagName === 'TEXTAREA' ||
      ele.tagName === 'INPUT' ||
      ele.tagName === 'BUTTON' ||
      _role === 'button' ||
      ele.tabIndex >= 0 ||
      _dataInTable ||
      _dataControlBySelf) &&
    _role !== 'menu';

  return _res;
};

const isClickableEle = (ele: any) => {
  const _role = ele.getAttribute('role');
  return (
    (ele.onclick ||
      ele.tagName === 'A' ||
      ele.tagName === 'TEXTAREA' ||
      ele.tagName === 'INPUT' ||
      ele.tagName === 'BUTTON' ||
      _role === 'button') &&
    _role !== 'menu'
  );
};

const isNeedFindInChildrenEle = (ele: any) => {
  const _role = ele.getAttribute('role');
  const _dataControlBySelf = ele.getAttribute('data-cotrol-self') === 'true';
  return _role !== 'menu' && !_dataControlBySelf;
};

const isEditableEle = (ele: any) => {
  return ele.tagName === 'INPUT' || ele.tagName === 'TEXTAREA';
};

export const setClickableChildrenUnFocusable = (ele: any, reFocusEle?: any) => {
  const _children: Array<any> = ele.children;
  if (_children && _children.length) {
    for (let _child of _children) {
      const _isFocusableEle = isFocusableEle(_child);
      if (_isFocusableEle) {
        _child.tabIndex = -1;
        _child.onkeydown = (e: any) => {
          // ifEventStopEscape(e, () => {
          //   if (reFocusEle) {
          //     reFocusEle.focus();
          //   }
          // });
        };
      }
      setClickableChildrenUnFocusable(_child, reFocusEle);
    }
  }
};

export const setEditableChildrenUnFocusable = (ele: any, reFocusEle?: any) => {
  const _children: Array<any> = ele.children;
  if (_children && _children.length) {
    for (let _child of _children) {
      const _isEditableEle = isEditableEle(_child);
      if (_isEditableEle) {
        _child.tabIndex = -1;
        _child.onkeydown = (e: any) => {
          // ifEventStopEscape(e, () => {
          //   if (reFocusEle) {
          //     reFocusEle.focus();
          //   }
          // });
        };
      }
      setEditableChildrenUnFocusable(_child, reFocusEle);
    }
  }
};

export const findClickableChildren = (ele: any): Array<any> => {
  const _children: Array<any> = ele.children;
  const _clickableChildren: Array<any> = [];
  if (_children && _children.length) {
    Array.from(_children).forEach((_child) => {
      const _isFocusableEle = isFocusableEle(_child);
      const _isClickableEle = isClickableEle(_child);
      const _isNeedFindInChildrenEle = isNeedFindInChildrenEle(_child);
      if (_isFocusableEle) {
        _child.tabIndex = -1;
        _clickableChildren.push(_child);
      }
      if (_isClickableEle) {
        _child.setAttribute('clickable-in-table', true);
      }
      if (_child.children && _isNeedFindInChildrenEle) {
        _clickableChildren.push(...findClickableChildren(_child));
      }
    });
  }
  return _clickableChildren;
};

export const findFirstClickableChild = (ele: any) => {
  const _children: Array<any> = ele.children;
  let _clickableChild: any = null;
  if (_children && _children.length) {
    for (let _child of _children) {
      const _isFocusableEle = isFocusableEle(_child);
      if (_isFocusableEle) {
        _clickableChild = _child;
      } else if (!_clickableChild) {
        _clickableChild = findFirstClickableChild(_child);
      }
    }
  } else {
    return null;
  }
  return _clickableChild;
};

export const findFirstEditableChild = (ele: any) => {
  const _children: Array<any> = ele.children;
  let _editableChild: any = null;
  if (_children && _children.length) {
    for (let _child of _children) {
      const _isEditableEle = isEditableEle(_child);
      if (_isEditableEle) {
        _editableChild = _child;
      } else if (!_editableChild) {
        _editableChild = findFirstEditableChild(_child);
      }
    }
  } else {
    return null;
  }
  return _editableChild;
};

export const uuid = (len = 16, radix?: number) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
};

export const tirmDoubleQuota = (str: string) => {
  if (str.startsWith('"') && str.endsWith('"')) {
    return str.slice(1, -1);
  } else {
    return str;
  }
};

export const getNameAbbrev = (firstName: string, lastName: string) => {
  if (firstName && lastName) return `${firstName.charAt(0) + lastName.charAt(0)}`;
};

export const changeEleToFirstPlace = (arr: any[], id: string) => {
  const index = arr?.findIndex((ele) => ele.role === 'owner');
  const ele = arr?.splice(index, 1)[0];
  arr.unshift(ele);
  return arr;
};

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getRoleValue(data: any[], label: string) {
  return data?.filter((r: IRoleType) => r.label === label)[0]?.value;
}

export function transformTime(dateString: string): any {
  let minuteTimestamp = 60 * 1000;
  let hourTimestamp = minuteTimestamp * 60;
  let dayTimestamp = hourTimestamp * 24;
  let monthTimestamp = dayTimestamp * 30;

  let currentTimestamp = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
  let createdTimestamp = new Date(dateString).getTime();

  if (!createdTimestamp) {
    return '';
  }

  let diffTimestamp = currentTimestamp - createdTimestamp;

  let minuteDiffTime = diffTimestamp / minuteTimestamp;
  let hourDiffTime = diffTimestamp / hourTimestamp;
  let dayDiffTime = diffTimestamp / dayTimestamp;
  let monthDiffTime = diffTimestamp / monthTimestamp;

  if (Math.floor(monthDiffTime) >= 1) {
    return getDate(dateString);
  } else if (Math.floor(dayDiffTime) >= 1) {
    return Math.floor(dayDiffTime) + ' day' + (Math.floor(dayDiffTime) > 1 ? 's ago' : ' ago');
  } else if (Math.floor(hourDiffTime) >= 1) {
    return Math.floor(hourDiffTime) + ' hour' + (Math.floor(hourDiffTime) > 1 ? 's ago' : ' ago');
  } else if (Math.floor(minuteDiffTime) >= 1) {
    return Math.floor(minuteDiffTime) + ' minute' + (Math.floor(minuteDiffTime) > 1 ? 's ago' : ' ago');
  } else {
    return 'Just now';
  }
}

export function getDate(timeStr: string) {
  let createdDate = new Date(timeStr);
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  let createdYear = createdDate.getFullYear();
  let createdMonth = createdDate.getMonth();
  let createdDay = createdDate.getDate();
  let createdMonthStr = monthArr[createdMonth] || '';
  return `${createdMonthStr} ${createdDay}, ${createdYear}`;
}

export function validateUrlForAutoScan(usrInputUrls: any[] = [], existedUrls: any[] = []) {
  const duplicatedUrls = usrInputUrls.filter((url) => existedUrls.includes(url));
  if (duplicatedUrls.length > 0) {
    return {
      error: 'duplitated',
      errMsg: "The URL you're trying to add is already in the list. Please enter a unique URL.",
    };
  }

  let invalidUrls = [];
  usrInputUrls.forEach((url) => {
    if (url?.lastIndexOf('https') > 0) {
      invalidUrls.push(url);
    }
  });
  if (invalidUrls.length > 0) {
    return {
      error: 'invalid',
      errMsg: 'Please enter a valid URL format. Example: https://example.com',
    };
  }

  const sameUrlsArr: any[] = [];
  let res;
  usrInputUrls.forEach((u) => {
    if (sameUrlsArr.includes(u)) {
      res = {
        error: 'same',
        errMsg: "Looks like you've entered the same URL more than once. Please add unique URLs.",
      };
    } else {
      sameUrlsArr.push(u);
    }
  });
  return res;
}

export const mergeSameEleInTwoArrays = (target: any[] = [], arr2: any[] = []) => {
  return target.map((ele) => {
    const _target = arr2.filter((itm) => itm.siteUrl === ele.url)[0] || {};
    return { ...ele, ..._target, scanStatus: _target.scanStatus };
  });
};

export function encryptData(str: any) {
  if (!str) return str;
  const secretKey = process.env.REACT_APP_env!; // Replace 'your-secret-key' with your actual secret key
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(str), secretKey).toString();
  return encrypted;
}

export function decryptData(str: any) {
  if (!str) return str;
  const secretKey = process.env.REACT_APP_env!; // Use the same secret key as used in encryptData
  try {
    const bytes = CryptoJS.AES.decrypt(str, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData; // Assuming the original data was an object
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // or handle error as needed
  }
  // return jwt.decode(str);
}

export function setCookie(name: string, value: string, days: number = .5) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

export function getCookie(name: string) {
  const nameEQ = encodeURIComponent(name) + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return undefined;
}

export function safelyParseJSON (json: any) {
  let parsed;
  try {
    parsed = JSON.parse(json)
  } catch (e) {
    return null;
  }
  return parsed;
}

export const removeHighlight = (anchorRef: any, currentIndex?: number) => {
  if((anchorRef.current! as HTMLElement)?.querySelectorAll(`[id^=anchor-]`))
  [].forEach.call((anchorRef.current! as HTMLElement)?.querySelectorAll(`[id^=anchor-]`), function (el: HTMLElement, index: number) {
      if (!((currentIndex || currentIndex === 0) && index === currentIndex)) el?.classList.remove("selected");
  });
}

export const formatUrl = (url: string) => {
  return url?.toLowerCase().replace(/\s+|\&+/g, '');
}

export const addSideAnchorClass = (anchorRef: any, trigger?: HTMLElement) => {
  if (!trigger?.classList.contains('selected')) {
      removeHighlight(anchorRef);
      trigger?.classList.add('selected');
  }
}

const generateUniqueIdentifier = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const getTabIdentifier = () => {
  let tabId = sessionStorage.getItem('tabId');
  if (!tabId) {
    tabId = generateUniqueIdentifier();
    sessionStorage.setItem('tabId', tabId);
  }
  return tabId;
};

export const setProductInfoCookie = (productInfo: any) => {
  const tabId = getTabIdentifier();
  const cookieName = `productInfo_${tabId}`;
  delete productInfo?.members;
  document.cookie = `${cookieName}=${JSON.stringify(productInfo)}; path=/`;
};

export const getProductInfoCookie = () => {
  const tabId = getTabIdentifier();
  const cookieName = `productInfo_${tabId}`;
  const cookies = document.cookie.split('; ');
  const productInfoCookie = cookies.find(cookie => cookie.startsWith(cookieName));
  return productInfoCookie ? productInfoCookie.split('=')?.[1] : null;
};

export const sanitizeHtml = (html: string) => {
  const cleanHtml = xss(html,{
    whiteList: {},stripIgnoreTag: true});
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = cleanHtml;
  return tempDiv.textContent || tempDiv.innerText || "";
};