import React from 'react';
import LoginPattern, { LanguageText } from './Login';
import { ItemDataType } from '@appkit4/react-components';
import './index.scss';
import Form from './Form';
import Entry from '@layout/Entry';
import { getCookie } from '@utils/common';

export default function LoginGeneric() {

  const textLanguages: LanguageText = {
    en: {
      name: 'English',
      value: {
        title: 'Welcome to',
        name: 'Accessibility Checker',
        subName: `Your one-stop shop to enhance your product's accessibility compliance.`,
        loginText: 'Login',
        ssoLoginText: 'SSO login',
        footerContent: "© 2024 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.",
        footerLinks: ['Terms and conditions']
      }
    }
  }

  const [language, setLanguage] = React.useState('en');
  const [isClickedLogin, setIsClickedLogin] = React.useState(false);
  let defaultLoginValue: any = {};
  Object.assign(defaultLoginValue, textLanguages[language]?.value);
  const [loginValue, setLoginValue] = React.useState(defaultLoginValue);
  delete loginValue['footerContent'];
  delete loginValue['footerLinks'];
  delete loginValue['loginText'];
  delete loginValue['ssoLoginText'];
  const onExternalLogin = () => {
    setIsClickedLogin(true);
    sessionStorage.setItem('loginType', 'external');
  }
  const onSSOLogin = () => {
    setIsClickedLogin(true);
    sessionStorage.setItem('loginType', 'internal');
  };

  const labels = React.useMemo(() => {
    return (textLanguages as LanguageText)[language]?.value;
  }, [language, textLanguages])

  return (
    isClickedLogin || (sessionStorage.getItem('loginType')&&sessionStorage.getItem('loginType')!=='') ||
    (!!getCookie('idToken') && getCookie('checkSuccess')==='true') ? <Entry></Entry> : <LoginPattern className="generic" language={language} textLanguages={textLanguages}
      renderLogin={<Form
        {...loginValue}
        onCancel={onExternalLogin}
        onOk={onSSOLogin}
        headerLayout="vertical"
        footerLayout="vertical"
        cancelText={labels.loginText}
        okText={labels.ssoLoginText}
        header={
          <>
            <span className='ap-pattern-form-login-welcome'>
              {labels.title}
            </span>
            <div className='ap-pattern-form-login-title'>
              {labels.name}
            </div>
            <div className='ap-pattern-form-login-sub-title'>
              {labels.subName}
            </div>
          </>
        }
      ></Form>}
    >
    </LoginPattern>
  )
}
