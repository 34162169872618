import {
  AddAssetUrlInputType,
  AssetAssessmentApiType,
  AssetCredentialInputType,
  AssetUrlApiDataType,
  ResponseErrorDataType,
} from '@models';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiService } from './rxjs.service';
import { JSEncrypt } from 'jsencrypt';

// TODO: change product id
const getAssetUrls = (productId: number | null) => {
  return apiService.get(`/product/sites?productId=${productId || ''}`);
};

const getCrawlerChildUrls = (productId: number | null): Observable<{ crawlerChildUrls: Array<string> }> => {
  return apiService.get(`/product/info/crawlerChildUrls?productId=${productId || ''}`);
};

// TODO: change product id
const addAssetUrl = (
  productId: number,
  urlOptions: AddAssetUrlInputType,
): Observable<AssetUrlApiDataType | ResponseErrorDataType> => {
  const input = {
    productId: productId,
    site: urlOptions,
  };
  return apiService.post('/product/site', input);
};

const deleteAssetUrl = (id: number) => {
  return apiService.delete(`/product/site?id=${id}`);
};

const updatePrimaryUrl = (id: number) => {
  return apiService.get(`/product/site/setPrimary?id=${id}`);
};

/**
 * encrypt
 * @param word
 * @returns {*}
 */
const encrypt = (word: string) => {
  if (!word || word.trim().length === 0) return '';
  const encryptor = new JSEncrypt();
  const pubKey = process?.env?.REACT_APP_PWD_PKEY;
  if (pubKey) {
    encryptor.setPublicKey(pubKey);
    const rsaPassWord = encryptor.encrypt(word);
    return rsaPassWord;
  }
};

const updateAssetCredential = (
  input: any,
): Observable<{
  success: boolean;
  message: string;
}> => {
  const authPass = input?.authPass;
  if (authPass) {
    const encryptedPass = encrypt(authPass);
    if (encryptedPass) {
      input = { ...input, authPass: encryptedPass };
    }
  }
  return apiService.post(`/product/credential`, input);
};

// TODO: change product id
const createAssessmentWork = (
  productId: string | number,
  assessmentId: number | null,
): Observable<{
  isUrlChange: number;
}> => {
  return apiService.post(`/assessment/create`, {
    productId,
    assessmentId,
  });
};

const getCompletedAssessment = (productId: number | null): Observable<AssetAssessmentApiType> => {
  return apiService.get(`/assessment/info?productId=${productId || ''}&type=lastDone`);
};

const getLatestAssessment = (productId: number | null): Observable<AssetAssessmentApiType> => {
  return apiService.get(`/assessment/info?productId=${productId || ''}`);
};

const sendPasscode = (input: { assessmentId: string; passcode: string; ttl: number }) => {
  return apiService.post(`/assessment/passcode`, input);
};

const countdownPasscode = (assessmentId: string) => {
  return apiService.get(`/assessment/passcode/countdown?assessmentId=${assessmentId}`);
};

const socpeChange = (
  productId: number | null,
  // assessmentId: number | null;
): Observable<any> => {
  return apiService.get(`/assessment/create/checkIfScopeChanged?productId=${productId || ''}`);
};

// listener for asset credential input change
const assetCredentialInputUpdateSub$ = new BehaviorSubject<any>(null);

const setDataRentention = (input: { productId: string | number; yesOrNo: number }) => {
  return apiService.post(`/assessment/retention`, input);
};

export const assetService = {
  getAssetUrls,
  getCrawlerChildUrls,
  addAssetUrl,
  deleteAssetUrl,
  updatePrimaryUrl,
  updateAssetCredential,
  createAssessmentWork,
  getCompletedAssessment,
  getLatestAssessment,
  sendPasscode,
  socpeChange,
  assetCredentialInputUpdateSub$,
  setDataRentention,
  countdownPasscode
};
