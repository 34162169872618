import React, { useEffect, useMemo, useRef, useState, useContext, useCallback } from 'react';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { Search } from '@appkit4/react-components/search';
import { Button } from '@appkit4/react-components/button';
import { Select, Badge } from '@appkit4/react-components';
import { Pagination } from '@appkit4/react-components/pagination';
import { Tag } from '@appkit4/react-components/tag';
import './issues.scss';
import IssueDashboard from './IssueDashboard';
import { IIssueType, ItemType, IIssueDashboardType } from './types';
import IssueFiltersDashboard from './IssueFiltersDashboard';
import Success from '../../assets/icons/Success.svg';
import Welcome from '../../assets/icons/welcome.svg';
import NoSearchResultsSvg from '../../assets/icons/noSearchResults.svg';
import NotificationNew from '@components/Notification';
import { issueService } from '@services/issue.service';
import { catchError, concatMap, take, switchMap } from 'rxjs/operators';
import { useLocation, useSearchParams } from 'react-router-dom';
import { filtersStatusPartData, filtersSeverityData, filterEffortLevelData, filtersWcagData } from './constants';
import LoadingComponent from '@components/Loading';
import { assetService } from '@services/asset.service';
import { Observable, Subscription, of } from 'rxjs';
import { capitalizeFirstLetter, decryptData, getCookie, getProductInfoCookie, safelyParseJSON, sanitizeHtml } from '@utils/common';
import _ from 'lodash';
import moment from 'moment';
import FooterPage from '@components/Footer';
import { useNavigate } from 'react-router-dom';
import ScanModal from '@components/ScanModal';
import ReRunScanSvg from '@assets/icons/RerunScan.svg';
import NoIssueToManageSvg from '@assets/icons/no-products.svg';
import { productService } from '@services';
import { NoResultsPage } from '@components/Home';
import { EntryContext } from '@layout/Entry';

const tabLabels = [
  { name: 'Automated scan', value: 'automatedscan' },
  { name: 'Manual review', value: 'manualreview' },
];

const Issues = () => {
  const {
    setNewestAssessmentId,
    setCloseScanModalStatus,
    closeScanModalMethod,
    setCloseScanModalMethod,
    setReScanBtnDisabled,
    reScanBtnDisabled,
    currentProductIdAndAssessmentId,
    saveIssueClicked,
    setSaveIssueClicked
  } = useContext(EntryContext);
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setactiveIndex] = React.useState(0);
  const [showNotification, setShowNotification] = React.useState(false);
  const [showNarrowFilter, setShowNarrowFilter] = React.useState(false);
  const narrowScreenFilterItmPartRef = useRef<HTMLDivElement>(null);
  const narrowScreenFilterBtnRef = useRef<HTMLButtonElement>(null);
  const [issueDashboardItems, setIssueDashboardItems] = useState<IIssueType[]>([]);
  const [filterItemsInResponse, setFilterItemsInResponse] = useState<any>('');
  const [assessmentId, setAssessmentId] = useState('');
  const [latestAssessmentId, setLatestAssessmentId] = useState('');

  const [issueTotalNum, setIssueTotalNum] = useState('');
  const [issueAllPageCnt, setIssueAllPageCnt] = useState('');
  const [lastScanDateTime, setLastScanDateTime] = useState('');

  const appId = location.state?.appId;
  const narrowScreenSelectedItemsHandler = (tabIndex: number, issueDashboardAreaItems: IIssueType[]) => {
    // not for narrow screen
    // if (issueDashboardAreaItems.length === 0) return [];
    // if (tabIndex === 0) return [filtersStatusPartData[0]];
    // if (tabIndex === 1) return filtersStatusPartData;
    // return [];

    return [filtersStatusPartData[0]];
    // not for narrow screen
  };

  const [filtersStatusPartDataNew, setFiltersStatusPartDataNew] = useState<any[]>([]);
  const [filtersSeverityDataNew, setFiltersSeverityDataNew] = useState<any[]>([]);
  const [filtersEffortLevelDataNew, setFiltersEffortLevelDataNew] = useState<any[]>([]);
  const [filtersWcagDataNew, setFiltersWcagDataNew] = useState<any[]>([]);
  const [narrowScreenSelectedItems, setNarrowScreenSelectedItems] = useState<ItemType[]>(
    narrowScreenSelectedItemsHandler(activeIndex, issueDashboardItems),
  );

  const [narrowFilterSelectedItems, setNarrowFilterSelectedItems] = useState<ItemType[]>(
    narrowScreenSelectedItemsHandler(activeIndex, issueDashboardItems),
  );

  const [autoScanedUrls, setAutoScanedUrls] = useState<any[]>([]);

  const autoScanTblDataRef = useRef<any[]>([]);

  const [searchParm, setSearchParm] = useState('');
  const [searchUsrInput, setSearchUsrInput] = useState('');

  const [showLoading, setShowLoading] = useState(false);

  const [showScanModal, setShowScanModal] = useState(false);

  const [isAssetAssessmentScanning, setIsAssetAssessmentScanning] = useState(false);
  const [isManualReviewStart, setIsManualReviewStart] = useState(false);

  const [showFilterLoading, setShowFilterLoading] = useState(false);
  let deleteSubRef: Subscription;
  useEffect(() => {
    const appId = search.get('appId');
    let v2AccessRef: Subscription;
    if (appId) {
      v2AccessRef = productService
        .v2GetPrivateAssetMemberDetail(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.status === 1 && !res?.isMember) {
            navigate('/v2/forbidden', {
              state: {
                origin: 'issues',
                productName: res?.name,
                ownerEmail: res?.ownerName,
              },
            });
          }
        });
    }
    return () => {
      v2AccessRef?.unsubscribe();
      deleteSubRef?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('curIssueTypeTabIdx', String(activeIndex));
  }, [activeIndex]);

  useEffect(() => {
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    let getManualCheckForDashboardSub: Subscription;
    if (productId) {
      getManualCheckForDashboardSub = issueService.getManualCheckForDashboard({ productId }).subscribe((res) => {
        const { manualcheckStatus } = res;
        if (manualcheckStatus) {
          if (manualcheckStatus === 0) {
            setIsManualReviewStart(false);
          } else if ([1, 2].includes(manualcheckStatus)) {
            setIsManualReviewStart(true);
          }
        }
      });
    }

    return () => {
      getManualCheckForDashboardSub?.unsubscribe();
    };
  }, []);

  const onTabChange = (i: number, value: string) => {
    setSearchUsrInput('');
    setSearchParm('');
    if (narrowScreenSelectedItems?.length === 0) {
      if (i === 1) {
        if (isManualReviewStart) {
          setNarrowScreenSelectedItems([{ ...filtersStatusPartData[0], checked: true }]);
        }
      } else {
        setNarrowScreenSelectedItems([{ ...filtersStatusPartData[0], checked: true }]);
      }
    }
    setFiltersStatusPartDataNew(
      filtersPartDataHandler(i, filtersStatusPartData, issueDashboardItems, filterItemsInResponse),
    );
    setFiltersSeverityDataNew(
      filtersPartDataHandler(i, filtersSeverityData, issueDashboardItems, filterItemsInResponse),
    );
    setFiltersEffortLevelDataNew(
      filtersPartDataHandler(i, filterEffortLevelData, issueDashboardItems, filterItemsInResponse),
    );
    setFiltersWcagDataNew(filtersPartDataHandler(i, filtersWcagData, issueDashboardItems, filterItemsInResponse));
    setactiveIndex(i);
    localStorage.setItem('curIssueTypeTabIdx', String(i));
  };

  const [pageShowCnt, setPageShowCnt] = React.useState<any>('5');
  const data = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ];

  const [currentPage, setCurrentPage] = React.useState(1);

  const getFilterItemName = (belongsTo: string) => {
    return narrowScreenSelectedItems.filter((itm) => itm?.belongsTo === belongsTo).map((ele) => ele.name);
  };

  const getFilterStatusItemName = (belongsTo: string) => {
    const statusItem = narrowScreenSelectedItems.filter((itm) => itm?.belongsTo === belongsTo).map((ele) => ele.name);
    const queryStatusArr = [];
    if (activeIndex === 1) {
      if (statusItem.indexOf('Closed issues') > -1) {
        queryStatusArr.push(0);
      }
      if (statusItem.indexOf('Open issues') > -1) {
        queryStatusArr.push(1);
      }
    } else {
      if (statusItem.indexOf('Open issues') > -1) {
        queryStatusArr.push(1);
      }
    }
    return queryStatusArr;
  };

  const issueDataHandler = (data: any) => {
    return data.map((item: any) => ({
      auditItemId: item.axeRule,
      id: item.id,
      issueType: item.status === 1 ? 'open' : 'close',
      issueLabel: activeIndex === 0 ? item?.description : item?.summarize,
      issueSrc: activeIndex === 0 ? item?.content : sanitizeHtml(item?.detailDescription),
      acNumber: item?.number,
      issueLevel: capitalizeFirstLetter(item?.severity),
      effortLevels: [item?.effort, item?.category],
      detail: {
        details: {
          description: `      Element has insufficient color contrast of 3.91 (foreground color: #d04a02, background color: #efefef, font
            size: 9.0pt (12px), font weight: normal). Expected contrast ratio of 4.5:1.`,
          issueCodeSnippet: item.content,
          scopeAndImpact: {
            conformaceLevel: `A`,
            wcagCriteriaNotMet: `1.1.1 Non-text content`,
            issueScope: 'Global issue that affects multiple pages.',
          },
        },
        occurrences: {
          impactedPages: [
            `https://hq.pwc.com/our-clients-and-business/delivering-one-firm-to-clients/strategic-negotiation-and-pricing-team.html`,
          ],
        },
      },
    }));
  };

  const filtersPartDataHandler = (
    tabIndex: number,
    data: ItemType[],
    issueDashboardAreaItems: IIssueType[],
    filterDataInResponse: any,
  ) => {
    const len = issueDashboardAreaItems.length;
    const getCheckedStatus = (value: string) => {
      // 初始化进来时
      if (len === 0 && narrowScreenSelectedItems.length === 0 && !searchUsrInput) return false;
      if (tabIndex === 0) {
        if (filtersStatusPartData.map((item: ItemType) => item.value).includes(value)) {
          return value === 'open';
        }
      } else {
        const openItem = filtersStatusPartDataNew.find((itm) => itm.value === 'open');
        const closeItem = filtersStatusPartDataNew.find((itm) => itm.value === 'close');
        const statusArr = getFilterStatusItemName('status');
        if (value === 'open') {
          if (isManualReviewStart === false) {
            return false;
          }
          return openItem.checked || (statusArr.length === 1 && statusArr[0] === 1);
        }
        if (value === 'close') {
          if (isManualReviewStart === false) {
            return false;
          } else {
            const statusparam = getFilterStatusItemName('status');
            if (statusparam.includes(0)) {
              return true;
            }
          }
          return closeItem.checked;
        }
      }
      if (narrowScreenSelectedItems.map((item: ItemType) => item.value).includes(value)) {
        return true;
      }

      return false;
    };
    const getDisabledStatus = (value: string) => {
      if (len === 0 && narrowScreenSelectedItems.length === 0 && !searchUsrInput) return true;
      if (filtersStatusPartData.map((item: ItemType) => item.value).includes(value)) {
        if (tabIndex === 0) {
          return !(value === 'open');
        }
        if (tabIndex === 1) {
          if (isManualReviewStart === false) {
            return true;
          }
          return false;
        }
      }
      if (activeIndex === 1) {
        if (isManualReviewStart === false) {
          return true;
        }
      }
      return false;
    };
    const getCnt = (value: string) => {
      // if (len === 0 && narrowScreenSelectedItems.length === 0) return 0;
      const keys = Object.keys(filterDataInResponse);
      const idx = keys.findIndex((key) => key.toLocaleLowerCase().replaceAll(' ', '') === value);
      return filterDataInResponse[keys[idx]];
    };

    return data.map((item: ItemType) => {
      const { value } = item;
      return {
        ...item,
        checked: getCheckedStatus(value),
        disabled: getDisabledStatus(value),
        cnt: getCnt(value) || 0,
      };
    });
  };

  useEffect(() => {
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    const resSubs = assetService
      .getAssetUrls(productId)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.status === 1) {
          setAutoScanedUrls(res.data);
          autoScanTblDataRef.current = res.data.map((url: any, idx: number) => ({
            ...url,
            id: idx + 1,
            pageTitle: url?.nickName,
            url: url?.siteUrl,
            scanStatus: url?.scanStatus,
            action: 'Delete',
          }));
        }
      });

    return () => {
      resSubs?.unsubscribe();
    };
  }, []);

  const loadPageListSubRef = useRef<Subscription | null>(null);
  const loadPageList = () => {
    const productId =
      (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId') || currentProductIdAndAssessmentId?.curProductId;
    setShowLoading(true);
    setCurrentPage(currentPage === 0 ? 1 : currentPage);
    loadPageListSubRef.current = assetService
      .getLatestAssessment(productId)
      .pipe(
        take(1),
        switchMap((res) => {
          if (Object.keys(res).length === 0) return of(null);
          const assessmentId = res.id;
          setAssessmentId(String(res.id));
          localStorage.setItem('assessmentId', String(res.id));
          setLatestAssessmentId(String(res.id));
          setNewestAssessmentId && setNewestAssessmentId(String(res.id));
          const defaultInput = {
            assessmentId: Number(assessmentId),
            category: getFilterItemName('wcagcategory'),
            severity: getFilterItemName('severity'),
            effort: getFilterItemName('effortlevel'),
            assessment: activeIndex === 0 ? 'auto' : 'manual',
            pageSize: Number(pageShowCnt),
            pageNumber: currentPage === 0 ? 1 : currentPage,
            description: searchParm,
            productId: productId,
            status: getFilterStatusItemName('status'),
          };
          return issueService.getPageListData(defaultInput);
        }),
        take(1),
      )
      .subscribe((res) => {
        if (res) {
          const _res = issueDataHandler(res.details);
          const _filterItemRes = res?.filterNum;
          setLastScanDateTime(res?.scanDate);
          setFiltersStatusPartDataNew(filtersPartDataHandler(activeIndex, filtersStatusPartData, _res, _filterItemRes));
          setFiltersSeverityDataNew(filtersPartDataHandler(activeIndex, filtersSeverityData, _res, _filterItemRes));
          setFiltersEffortLevelDataNew(
            filtersPartDataHandler(activeIndex, filterEffortLevelData, _res, _filterItemRes),
          );
          setFiltersWcagDataNew(filtersPartDataHandler(activeIndex, filtersWcagData, _res, _filterItemRes));

          setIssueDashboardItems(_res);
          setFilterItemsInResponse(_filterItemRes);
          setIssueTotalNum(res?.filterNum?.issuesNum);
          setIssueAllPageCnt(Math.ceil(Number(res?.filterNum?.issuesNum) / pageShowCnt).toString());
          setShowLoading(false);
        } else {
          setIssueDashboardItems([]);
          setFiltersStatusPartDataNew(filtersPartDataHandler(activeIndex, filtersStatusPartData, [], []));
          setFiltersSeverityDataNew(filtersPartDataHandler(activeIndex, filtersSeverityData, [], []));
          setFiltersEffortLevelDataNew(filtersPartDataHandler(activeIndex, filterEffortLevelData, [], []));
          setFiltersWcagDataNew(filtersPartDataHandler(activeIndex, filtersWcagData, [], []));
          setShowLoading(false);
        }
      });
  };

  const loadFilterPageListSubRef = useRef<Subscription | null>(null);
  const loadFilterPageList = (searchValue?: string) => {
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    setShowFilterLoading(true);
    setCurrentPage(currentPage === 0 ? 1 : currentPage);
    loadFilterPageListSubRef.current = assetService
      .getLatestAssessment(productId)
      .pipe(
        take(1),
        switchMap((res) => {
          if (Object.keys(res).length === 0) return of(null);
          const assessmentId = res.id;
          setAssessmentId(String(res.id));
          localStorage.setItem('assessmentId', String(res.id));
          setLatestAssessmentId(String(res.id));
          setNewestAssessmentId && setNewestAssessmentId(String(res.id));
          const defaultInput = {
            assessmentId: Number(assessmentId),
            category: getFilterItemName('wcagcategory'),
            severity: getFilterItemName('severity'),
            effort: getFilterItemName('effortlevel'),
            assessment: activeIndex === 0 ? 'auto' : 'manual',
            pageSize: Number(pageShowCnt),
            pageNumber: currentPage === 0 ? 1 : currentPage,
            description: searchValue||searchParm,
            productId: productId,
            status: getFilterStatusItemName('status'),
          };
          return issueService.getPageListData(defaultInput);
        }),
        take(1),
      )
      .subscribe((res) => {
        if (res) {
          const _res = issueDataHandler(res.details);
          const _filterItemRes = res?.filterNum;
          setLastScanDateTime(res?.scanDate);
          setFiltersStatusPartDataNew(filtersPartDataHandler(activeIndex, filtersStatusPartData, _res, _filterItemRes));
          setFiltersSeverityDataNew(filtersPartDataHandler(activeIndex, filtersSeverityData, _res, _filterItemRes));
          setFiltersEffortLevelDataNew(
            filtersPartDataHandler(activeIndex, filterEffortLevelData, _res, _filterItemRes),
          );
          setFiltersWcagDataNew(filtersPartDataHandler(activeIndex, filtersWcagData, _res, _filterItemRes));

          setIssueDashboardItems(_res);
          setFilterItemsInResponse(_filterItemRes);
          setIssueTotalNum(res?.filterNum?.issuesNum);
          setIssueAllPageCnt(Math.ceil(Number(res?.filterNum?.issuesNum) / pageShowCnt).toString());
          setShowFilterLoading(false);
        } else {
          setIssueDashboardItems([]);
          setFiltersStatusPartDataNew(filtersPartDataHandler(activeIndex, filtersStatusPartData, [], []));
          setFiltersSeverityDataNew(filtersPartDataHandler(activeIndex, filtersSeverityData, [], []));
          setFiltersEffortLevelDataNew(filtersPartDataHandler(activeIndex, filterEffortLevelData, [], []));
          setFiltersWcagDataNew(filtersPartDataHandler(activeIndex, filtersWcagData, [], []));
          setShowFilterLoading(false);
        }
      });
  };

  useEffect(() => {
    if (currentProductIdAndAssessmentId) {
      loadPageList();
    }
  }, [currentProductIdAndAssessmentId]);

  useEffect(() => {
    if (saveIssueClicked) {
      loadPageList();
      if(saveIssueClicked)
        setSaveIssueClicked?.(false);
    }
  }, [saveIssueClicked]);

  useEffect(() => {
    if (currentProductIdAndAssessmentId) {
      loadFilterPageList();
    }
  }, [pageShowCnt, currentPage, activeIndex, narrowScreenSelectedItems, currentProductIdAndAssessmentId]);

  const _fetchFilterData = useCallback(
    _.debounce((value, event) => loadFilterPageList(value), 1500),
    [activeIndex],
  );
  
  const onSearchChange = (value: string, event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {
    setSearchUsrInput(value);
    if (value) {
      setCurrentPage(1);
      setTimeout(function () {
        setSearchParm(value);
      }, 2000);
      _fetchFilterData(value, event);
    }
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const toPrePage = (v: any) => {};

  const toNexPage = (v: any) => {};

  const clearAll = () => {
    if (issueDashboardItems.length === 0 && narrowScreenSelectedItems.length === 0) return;
    if (narrowScreenSelectedItems.length === 1 && narrowScreenSelectedItems[0].value === 'open') return;
    setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setNarrowScreenSelectedItems(narrowScreenSelectedItemsHandler(activeIndex, issueDashboardItems));
  };

  const searchBtnInfo = useMemo(() => {
    if (activeIndex === 0)
      return {
        label: 'Run automated scan',
        icon: 'icon-analysis-outline',
      };
    if (activeIndex === 1)
      return {
        label: 'Open manual review',
        icon: 'icon-survey-checkmark-outline',
      };
  }, [activeIndex]);

  const btnClickHandler = () => {
    if (searchBtnInfo?.label === 'Open manual review') {
      navigate('/v2/manualIssue', {
        state: {
        origin: 'issues'
      }});
    }
    if (searchBtnInfo?.label === 'Run automated scan') {
      setShowScanModal(true);
      setCloseScanModalMethod && setCloseScanModalMethod(false);
      setCloseScanModalStatus && setCloseScanModalStatus(false);
      localStorage.setItem('scanModalFrom', 'reRun');
    }
  };

  const lastScanReviewLabel = useMemo(() => {
    if (activeIndex === 0) return 'Last scan';
    if (activeIndex === 1) return 'Last review';
  }, [activeIndex]);

  const getNoSearchResultsPageText = () => {
    return {
      imgSrc: NoSearchResultsSvg,
      boldTitlePartText: 'No issues found.',
      descPartText: `Try a different keyword or select a different filter option.`,
    };
  };
  const getNoUsrInputSearchResultsPageText = () => {
    return {
      imgSrc: NoSearchResultsSvg,
      boldTitlePartText: 'No issues found.',
      descPartText: `Try a different keyword or select a different filter option.`,
    };
  };

  const toggleNarrowScreenFilter = (event: MouseEvent) => {
    event.stopPropagation();
    setShowNarrowFilter(!showNarrowFilter);
  };

  const onNarrowScreenTagClose = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    tag: ItemType,
  ) => {
    setNarrowFilterSelectedItems((prevs) => prevs.filter((prev) => prev.value !== tag.value));
    setNarrowScreenSelectedItems((prevs) => prevs.filter((prev) => prev.value !== tag.value));
  };
  const clearNarrowScreenSelectedItems = () => {
    // if (narrowScreenSelectedItems.length === 0) return;
    // setNarrowFilterSelectedItems([]);
    // setNarrowScreenSelectedItems([]);
    // setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));

    if (issueDashboardItems.length === 0 && narrowFilterSelectedItems.length === 0) return;
    if (narrowFilterSelectedItems.length === 1 && narrowFilterSelectedItems[0].value === 'open') return;
    setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setNarrowFilterSelectedItems(narrowScreenSelectedItemsHandler(activeIndex, issueDashboardItems));
    setNarrowScreenSelectedItems(narrowScreenSelectedItemsHandler(activeIndex, issueDashboardItems));
  };

  useEffect(() => {
    function narrowScreenNotFilterPartClickHandler(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (narrowScreenFilterBtnRef.current?.contains(target)) {
        setShowNarrowFilter(!showNarrowFilter);
        return;
      }
      if (!narrowScreenFilterItmPartRef.current?.contains(target)) {
        setShowNarrowFilter(false);
      }
    }

    function hideNarrowFilterPart(event: any) {
      narrowScreenFilterItmPartRef.current?.classList.remove('show');
    }

    window.addEventListener('click', (event: MouseEvent) => narrowScreenNotFilterPartClickHandler(event));
    window.addEventListener('resize', (event: any) => hideNarrowFilterPart(event));
    return () => {
      window.removeEventListener('click', (event: MouseEvent) => narrowScreenNotFilterPartClickHandler(event));
      window.removeEventListener('resize', (event: any) => hideNarrowFilterPart(event));
    };
  }, []);

  useEffect(() => {
    return () => {
      loadPageListSubRef?.current?.unsubscribe();
      loadFilterPageListSubRef?.current?.unsubscribe();
    };
  }, []);

  const onNarrowScreenFiltersClearBtnHandler = () => {
    // setFiltersStatusPartDataNew((prev: ItemType[]) =>
    //   prev.map((itm) => ({ ...itm, checked: itm.value === 'open' ? true : false })),
    // );
    // setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setNarrowFilterSelectedItems([]);

    if (issueDashboardItems.length === 0 && narrowFilterSelectedItems.length === 0) return;
    if (narrowFilterSelectedItems.length === 1 && narrowFilterSelectedItems[0].value === 'open') return;

    setFiltersStatusPartDataNew((prev: ItemType[]) =>
      prev.map((itm) => ({ ...itm, checked: itm.value === 'open' ? true : false })),
    );
    setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setNarrowFilterSelectedItems(narrowScreenSelectedItemsHandler(activeIndex, issueDashboardItems));
  };

  const onNarrowScreenFiltersApplyBtnHandler = () => {
    setShowNarrowFilter(false);
    setNarrowScreenSelectedItems([...narrowFilterSelectedItems]);
  };

  const pageShowNumSelectorHandler = (vals: any) => {
    const tempAllCnt = Number(vals) * Number(currentPage);
    const finalCurrentPage =
      tempAllCnt > Number(issueTotalNum) ? Math.ceil(Number(issueTotalNum) / Number(vals)) : Number(currentPage);

    setCurrentPage(finalCurrentPage);
    setPageShowCnt(vals);
  };

  const reloadPage = () => {
    loadPageList();
  };

  const onCheckboxChange = () => {};

  const onCheckboxStatusChange = (result: any) => {
    const openItem = result.items.find((item: any) => item.value === 'open');
    if (openItem.checked && result.target.value === 'close') {
      localStorage.setItem('isCheckOpenStatus', 'true');
    }
  };

  const noIssuePageText = useMemo(() => {
    if (activeIndex === 0) {
      return {
        imgSrc: Success,
        boldTitlePartText: 'Great work! There are no open issues from the last automated scan.',
        descPartText: `To identify any new issues, it is recommended to rerun an automated scan if you have recently added new URLs,
      features or pages to your product.`,
        runAutoScanPartBtnText: 'Run automated scan',
        btnIcon: 'icon-analysis-outline',
      };
    }

    if (activeIndex === 1) {
      if (isManualReviewStart === false) {
        return {
          imgSrc: Welcome,
          boldTitlePartText: 'It appears that you have not yet begun the manual review process.',
          descPartText: `To ensure a thorough evaluation of your product and identify any accessibility issues, 
          please initiate the manual review.`,
          runAutoScanPartBtnText: 'Start manual review',
          btnIcon: 'icon-survey-checkmark-outline',
        };
      }
      return {
        imgSrc: Success,
        boldTitlePartText: 'Great work! There are no open issues from the last manual review.',
        descPartText: `Continue conducting regular manual reviews to ensure accessibility compliance.`,
        runAutoScanPartBtnText: 'Start manual review',
        btnIcon: 'icon-survey-checkmark-outline',
      };
    }
  }, [activeIndex]);

  const pageNumHandler = () => {
    setCurrentPage(1);
  };

  const onDeleteIssue = (issue: any) => {
    const param = {
      productId: Number(sessionStorage.getItem('productId')) || Number(currentProductIdAndAssessmentId?.curProductId),
      issueId: issue?.id
    };
    deleteSubRef = productService
    .deleteManualIssue(param)
    .pipe(take(1))
    .subscribe(
      (res) => {
        if (res.message === 'success') {  
          loadPageList();
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  const getColNineContent = () => {
    const getNextContent = () => {
      if (issueDashboardItems?.length > 0) {
        return (
          <>
            <div className="narrowChoosedFilterItemsPart">
              <div className="narrow-clearFilters-part" onClick={clearNarrowScreenSelectedItems}>
                Clear filters
              </div>
              <div className="narrowScreenFIlterDivider"></div>
              <div className="narrow-selectedItems-part">
                {narrowFilterSelectedItems?.map((tag, idx: number) => (
                  <Tag
                    key={`${idx}-${tag.value}`}
                    closable={tag.value !== 'open'}
                    onClose={(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) =>
                      onNarrowScreenTagClose(event, tag)
                    }
                  >
                    {tag.name}
                  </Tag>
                ))}
              </div>
            </div>
            <div className="lastScanDatearea">
              <span className="showMsgArea">
                Showing {Number(pageShowCnt) * Number(currentPage) - Number(pageShowCnt) + 1}-
                {Number(pageShowCnt) * Number(currentPage) > Number(issueTotalNum)
                  ? issueTotalNum
                  : Number(pageShowCnt) * Number(currentPage)}{' '}
                of {issueTotalNum}
              </span>
              <div className="lastScanPart">
                <span>{lastScanReviewLabel}:</span>
                <span>{lastScanDateTime && moment(lastScanDateTime).format('MMM DD, YYYY h:mm:ss A')}</span>
              </div>
            </div>
            <div className="issueDashboardArea">
              {issueDashboardItems.map((issue: IIssueType, idx: number) => (
                <IssueDashboard
                  curIssueTypeTabIdx={activeIndex}
                  issue={issue}
                  key={`${idx}${issue.acNumber}`}
                  reloadPage={reloadPage}
                  onDeleteIssue={() => onDeleteIssue(issue)}
                />
              ))}
            </div>
            <div className="pageNationPart">
              <div className="showPart">
                <span>Show</span>
                <div className="pageDropdownPart">
                  <Select
                    data={data}
                    value={pageShowCnt}
                    searchable={false}
                    defaultValue={['5']}
                    placeholder=""
                    hideTitleOnInput={true}
                    dropdownAlwaysDown={false}
                    onSelect={pageShowNumSelectorHandler}
                  ></Select>
                </div>
                <span>items per page</span>
              </div>
              <div className="pagePart">
                <Pagination
                  current={currentPage}
                  total={Number(issueAllPageCnt)}
                  onPageChange={onPageChange}
                  toPreviousPage=""
                  toNextPage=""
                ></Pagination>
              </div>
            </div>
          </>
        );
      }

      if (isManualReviewStart === false) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={noIssuePageText?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{noIssuePageText?.boldTitlePartText}</div>
            <div className="descPart">{noIssuePageText?.descPartText}</div>
            <div className="runAutoScanPart">
              <Button kind="primary" icon={noIssuePageText?.btnIcon} onClick={noIssueGotoPage}>
                {noIssuePageText?.runAutoScanPartBtnText}
              </Button>
            </div>
          </div>
        );
      }

      if (issueDashboardItems?.length === 0 && narrowScreenSelectedItems.length === 0 && !searchUsrInput) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={noIssuePageText?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{noIssuePageText?.boldTitlePartText}</div>
            <div className="descPart">{noIssuePageText?.descPartText}</div>
            <div className="runAutoScanPart">
              <Button kind="primary" icon={noIssuePageText?.btnIcon} onClick={noIssueGotoPage}>
                {noIssuePageText?.runAutoScanPartBtnText}
              </Button>
            </div>
          </div>
        );
      }

      if (
        issueDashboardItems?.length === 0 &&
        narrowScreenSelectedItems.length === 1 &&
        narrowScreenSelectedItems[0].value === 'open' &&
        !searchUsrInput
      ) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={noIssuePageText?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{noIssuePageText?.boldTitlePartText}</div>
            <div className="descPart">{noIssuePageText?.descPartText}</div>
            <div className="runAutoScanPart">
              <Button kind="primary" icon={noIssuePageText?.btnIcon} onClick={noIssueGotoPage}>
                {noIssuePageText?.runAutoScanPartBtnText}
              </Button>
            </div>
          </div>
        );
      }
      if (issueDashboardItems?.length === 0 && narrowScreenSelectedItems.length > 0) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={getNoSearchResultsPageText()?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{getNoSearchResultsPageText()?.boldTitlePartText}</div>
            <div className="descPart">{getNoSearchResultsPageText()?.descPartText}</div>
          </div>
        );
      }

      if (searchParm && issueDashboardItems?.length === 0) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={getNoUsrInputSearchResultsPageText()?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{getNoUsrInputSearchResultsPageText()?.boldTitlePartText}</div>
            <div className="descPart">{getNoUsrInputSearchResultsPageText()?.descPartText}</div>
          </div>
        );
      }
    };
    const loadingBtn = <Button
      kind="primary"
      icon={searchBtnInfo?.icon}
      onClick={btnClickHandler}
      loading={true}
      disabled={true}>
      {searchBtnInfo?.label}
    </Button>;
    return (
      <>
        <div className="searchButtonContainer">
          <Button
            ref={narrowScreenFilterBtnRef}
            kind="secondary"
            className="searchButtonContainer-narrowFilter_iconPart"
            onClick={toggleNarrowScreenFilter}
          >
            <span className="Appkit4-icon icon-filter-outline filterIcon-cls"></span>
          </Button>
          <div ref={narrowScreenFilterItmPartRef} className={`filterItemsPart ${showNarrowFilter ? 'show' : 'hide'}`}>
            <div className="filterItemsPart-content">
              <div className="statusPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowFilterSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Status'}
                  items={filtersStatusPartDataNew}
                  tabActiveIndex={activeIndex}
                  setFiltersItemsPartData={setFiltersStatusPartDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxStatusChange}
                />
              </div>
              <div className="severityPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowFilterSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Severity'}
                  items={filtersSeverityDataNew}
                  setFiltersItemsPartData={setFiltersSeverityDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div className="effortLevelPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowFilterSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Effort level estimation'}
                  items={filtersEffortLevelDataNew}
                  setFiltersItemsPartData={setFiltersEffortLevelDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div className="wcagPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowFilterSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'WCAG category'}
                  items={filtersWcagDataNew}
                  setFiltersItemsPartData={setFiltersWcagDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
            </div>
            <div className="filterItemsPart-btnParts">
              <Button kind="secondary" onClick={onNarrowScreenFiltersClearBtnHandler}>
                Clear
              </Button>
              <Button kind="primary" onClick={onNarrowScreenFiltersApplyBtnHandler}>
                Apply
              </Button>
            </div>
          </div>
          <Search
            disabled={!isManualReviewStart && activeIndex === 1}
            searchType={'secondary'}
            placeholder="Search issue"
            onChange={onSearchChange}
            className="searchStyle"
            searchValue={searchUsrInput}
            onClear={() => {
              setSearchUsrInput('');
              setShowFilterLoading(true);
              setTimeout(function () {
                setSearchParm('');
              }, 1000);
              _fetchFilterData('', null);
            }}
          />
          {isManualReviewStart === false && searchBtnInfo?.label === 'Open manual review' ? null : (
            searchBtnInfo?.label === 'Run automated scan' && reScanBtnDisabled? loadingBtn : <Button
              kind="primary"
              icon={searchBtnInfo?.icon}
              onClick={btnClickHandler}
            >
              {searchBtnInfo?.label}
            </Button>
          )}
        </div>
        {showFilterLoading ? <LoadingComponent /> : getNextContent()}
      </>
    );
  };

  const goToAutoScanPage = () => {
    navigate('/v2/autoMatedScan', {
      state: {
        origin: 'issues',
        curProductInfo: safelyParseJSON(getProductInfoCookie()!),
      },
    });
  };

  const noIssueGotoPage = () => {
    if (noIssuePageText?.runAutoScanPartBtnText === 'Run automated scan') {
      navigate('/v2/autoMatedScan', {
        state: {
          origin: 'issues',
          curProductInfo: safelyParseJSON(getProductInfoCookie()!),
        },
      });
    } else if (noIssuePageText?.runAutoScanPartBtnText === 'Start manual review') {
      navigate('/v2/manualIssue', {
        state: {
        origin: 'issues'
      }});
    }
  };

  const getFilterHeaderDisabledStyle = () => {
    if (activeIndex === 1) {
      if (
        (issueDashboardItems.length === 0 && narrowScreenSelectedItems.length === 0) ||
        isManualReviewStart === false
      ) {
        return 'disbaledStyle';
      }
    }
    return '';
  };

  return (
    <>
      <div className={`issuesContainer`} tabIndex={-1}>
        <div className="row">
          <h1 className="issueLabel">Issues</h1>
        </div>
        {showLoading ? (
          <LoadingComponent />
        ) : latestAssessmentId ? (
          <>
            <div className="row">
              <div className="tabsContainer">
                <Tabs type="underline" activeIndex={activeIndex} onTabChange={onTabChange}>
                  {tabLabels.map((label: { name: string; value: string }, i: number) => (
                    <Tab label={label.name} value={label.value} key={`${i}-${label.value}`}></Tab>
                  ))}
                </Tabs>
              </div>
            </div>
            <div className="row">
              <div className="col-9">{getColNineContent()}</div>
              <div className="col-3 notNarrowScreenFiltersContent">
                <div className="notNarrowScreenFiltersArea">
                  <div className="filterHeader">
                    <span className={getFilterHeaderDisabledStyle()}>Filters</span>
                    <span className={getFilterHeaderDisabledStyle()} onClick={clearAll} tabIndex={0}>
                      Clear all
                    </span>
                  </div>
                  <div className="dataContent">
                    <div className="statusPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Status'}
                        items={filtersStatusPartDataNew}
                        setFiltersItemsPartData={setFiltersStatusPartDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxStatusChange}
                      />
                    </div>
                    <div className="severityPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Severity'}
                        items={filtersSeverityDataNew}
                        setFiltersItemsPartData={setFiltersSeverityDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                    <div className="effortLevelPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Effort level estimation'}
                        items={filtersEffortLevelDataNew}
                        setFiltersItemsPartData={setFiltersEffortLevelDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                    <div className="wcagPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'WCAG category'}
                        items={filtersWcagDataNew}
                        setFiltersItemsPartData={setFiltersWcagDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showNotification && <NotificationNew />}
          </>
        ) : (
          <div className="issuesContainer-noIssuesToManagePart">
            <NoResultsPage
              imgSrc={NoIssueToManageSvg}
              imgAlt="No issues to manage logo"
              titleArea="No issues to manage."
              descArea={`To get started, please select set up an automated scan to begin your journey to accessible and inclusive experiences. `}
              btnArea={
                <Button kind="primary" onClick={goToAutoScanPage}>
                  Set up automated scan
                </Button>
              }
            />
          </div>
        )}
      </div>

      {getProductInfoCookie() && <ScanModal
        curProductInfo={safelyParseJSON(getProductInfoCookie()!)}
        footer={<></>}
        visible={showScanModal && !closeScanModalMethod}
        setVisibleFunc={setShowScanModal}
        className="reRunScanModal"
        title="Confirmation"
        imgSrc={ReRunScanSvg}
        boldText="Confirm the pages to scan."
        descText="Ensure manual review issues are still applicable if adding or removing pages to scan. "
        originTableData={autoScanTblDataRef.current}
        isAssetAssessmentScanning={isAssetAssessmentScanning}
        setIsAssetAssessmentScanning={setIsAssetAssessmentScanning}
        origin="reRun"
        needGetAssetUrls={true}
      />}
    </>
  );
};

export default Issues;
