import React from 'react';
import './index.scss';
import ClassNames from 'classnames';
import {
  List,
  ListItem,
  Select
} from '@appkit4/react-components';
import LoadingComponent from '@components/Loading';

export const SectionListPart = ({ setShowIssueListContent, setAuditOpenIssueListData, setAuditResolvedIssueListData,
  setActiveKeys, getManualIssueOriginalData,
  summaryList, sectionIssuesCompletedList, sectionNameList, selectedId, setSelectedId, setShowIssueCategoryLoading, selectedType, setSelectedType,
  selectTypeCallback, originalIssueData, showLoadingSection, guidelineList, showLoading, setShowLoading
}: any) => {
  const data = [
    { value: 'categories', label: 'WCAG categories' },
    { value: 'guidelines', label: 'WCAG guidelines' }
  ];
  const handleKeyDown = (item: any, event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setSelectedId(item.id);
    }
  };

  const onSelectSection = (item: any) => {
    setShowLoading(true);
    setSelectedId(item.id);
    setShowIssueListContent(false);
    setAuditOpenIssueListData([]);
    setAuditResolvedIssueListData([]);
    setActiveKeys([]);
    setShowIssueCategoryLoading(true);
    getManualIssueOriginalData(item.sectionName, originalIssueData, null, true);
  };

  const renderItem = (item: any, index: number) => {
    const classes = ClassNames({ selected: item.id?.toString() === selectedId?.toString(), disabled: showLoading });
    const handleSectionName = (item: any) => {
      if (item.sectionName === 'Content Structure') return 'Structure';
      if (item.sectionName === 'Keyboard Control (Mobile)') return 'Keyboard';
      return item.sectionName ? item.sectionName : item.description;
    };
    const completedValue = summaryList.find((dataItem: any) => dataItem.sectionName === item.sectionName)?.completedValue || 0;
    const totalValue = summaryList.find((dataItem: any) => dataItem.sectionName === item.sectionName)?.totalValue || 0;
    return (
      <ListItem
        key={index}
        role="option"
        className={classes}
        aria-selected={item.id === selectedId}
        onClick={() =>
          onSelectSection(item)
        }
        onKeyDown={(e) => handleKeyDown(item, e)}
      >
        <div className='list-section-item'>
          <div className="list-section-item-title">
            {(() => {
              if (completedValue === 0) {
                return <span aria-hidden="true" className="Appkit4-icon icon-success-fill" key={index}></span>;
              } else if (completedValue > 0 && completedValue < totalValue) {
                return <span aria-hidden="true" className="incomplete-icon" key={index}></span>;
              } else if (sectionIssuesCompletedList.some((currenCompletedItem: any) => currenCompletedItem[0] === item.sectionName)) {
                return <span aria-hidden="true" className="Appkit4-icon completed icon-success-fill" key={index}></span>;
              }
              return null;
            })()}
            <span aria-label={handleSectionName(item)} className="primary-text ap-ml-spacing-2">{handleSectionName(item)}</span>
          </div>
          <div className='primary-text-count'>{completedValue} of {totalValue} checkpoints</div>
          <div>
          </div>
        </div>
      </ListItem>
    );
  };

  const getGuidlines = () => {
    return guidelineList?.map((categoryItem: any, index: number) => {
      const categoryName = guidelineList?.[index];
      const guidelines = sectionNameList.filter((item: any) => item.category === categoryName);
      return <div className='category-list'>
        <div className='category-list-title'>{(index+1).toString()}. {categoryName}</div>
        <List
          itemKey="id"
          bordered={true}
          data={guidelines}
          renderItem={renderItem}
          className={'custom-item-list guideline-list'}
          style={{ display: 'inline-block' }}
        ></List></div>
    });
  }

  const onSelectEvent = (value: any) => {
    setSelectedType(value);
    setSelectedId(0);
    setShowIssueListContent(false);
    setAuditOpenIssueListData([]);
    setAuditResolvedIssueListData([]);
    setActiveKeys([]);
    setShowIssueCategoryLoading(true);
    selectTypeCallback?.(value === 'guidelines');
  }

  return (
    <div className="manual-review-nav">
      <Select
        data={data}
        className='ap-mb-spacing-6'
        placeholder="View manual review by"
        required={false}
        onSelect={onSelectEvent}
        defaultValue={'categories'}
      ></Select>
      <div className='manual-review-list'> 
      {showLoadingSection ? <LoadingComponent></LoadingComponent> :
        <>
        <div className='manual-review-list-title'>Completed {sectionIssuesCompletedList.length} of {sectionNameList.length} {selectedType === 'categories' ? 'categories' : 'guidelines'}</div>
        {selectedType === 'categories' && (
          <List
          itemKey="id"
          bordered={true}
          data={sectionNameList}
          renderItem={renderItem}
          className={'custom-item-list'}
          style={{ display: 'inline-block' }}
        ></List>)}
        {selectedType === 'guidelines' && (
          <div className='guideline-wrapper'>
            {getGuidlines()}
          </div>
        )}
        </>}
      </div>
    </div>
  );
};