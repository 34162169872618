import React, { FC, memo, useMemo, useRef, useEffect, useContext, useState } from 'react';
import { IHeaderType } from './types';
import './header.scss';
import { Avatar } from '@appkit4/react-components/avatar';
import { DropdownButton } from '@appkit4/react-components/dropdown';
import { Header } from '@appkit4/react-components/header';
import { useLocation, useNavigate } from 'react-router-dom';
import { EntryContext } from '@layout/Entry';
import CommonModal from '@components/modals/CommonModal';
import { Switch } from '@appkit4/react-components/switch';
import { Button, toaster, Notification, Tooltip } from '@appkit4/react-components';
import { RadioGroup, Radio } from '@appkit4/react-components/radio';
import { authService } from '@services/auth.service';
import { Badge } from '@appkit4/react-components/badge';
import { wsService } from '@services/websocket.service';
import { productService, userService } from '@services';
import { catchError, take, switchMap } from 'rxjs/operators';
import { axios } from '@services/axios.service';
import { of } from 'rxjs';
import * as jwt from 'jsonwebtoken';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON } from '@utils/common';
import VerificationModal from '@components/VerificationModal';
import { Loading } from '@appkit4/react-components';
import { assetService } from '@services/asset.service';
const HeaderNew: FC<IHeaderType> = memo((props) => {
  const {
    setIsDarkMode,
    productName: _pName,
    setLatestAssessmentResult,
    closeScanModalStatus,
    scanModalFrom,
    setCloseScanModalMethod,
    setReScanBtnDisabled,
    showVerificationCode,
    setShowVerificationCode,
    setCloseScanModalStatus,
    verificationResultType,
    setVerificationResultType,
    setAssetScanningErrorTime
  } = useContext(EntryContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { productName } = props;
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);
  const [emailSwitchChecked, setEmailSwitchChecked] = useState(false);
  const [productId, setProductId] = useState<Number>(0);
  const [currentModeValue, setCurrentModeValue] = useState('lightMode');
  // const [curProductInfo, setCurProductInfo] = useState<any>();
  const [curLoginUserInfo, setCurProductInfo] = useState<any>();
  const [latestAssessmentUserId, setLatestAssessmentUserId] = useState<any>();
  const [productNameVerification, setProductNameVerification] = useState<string>();
  const [finalProductname, setFinalProductname] = useState('');
  const [authUser,setAuthUser] = useState('');
  const latestAssessmentRef = useRef<any>();
  const [headerDropDownData, setHeaderDropDownData] = useState([
    {
      value: 'preferences',
      label: 'Preferences',
      iconName: 'icon-setting-outline',
    },
    {
      value: 'signout',
      label: 'Sign out',
      iconName: 'icon-logout-outline',
    },
  ]);
  const [needHelpData, setNeedHelpData] = useState([
    {
      value: 'submitFeedback',
      label: 'Submit feedback',
      iconName: '',
    },
    {
      value: 'viewFAQ',
      label: 'View FAQ',
      iconName: '',
    },
  ]);
  
  const goToDashboardHandler = (scanAppId: string, scanProductId: string) => {
    const currentProductId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');

    if (scanProductId && currentProductId && scanProductId !== currentProductId) {
      navigate(`/v2/dwrapper/board?appId=${scanAppId}`);
      window.location.reload();
    } else {
      // if (location.pathname.includes('/v2/dwrapper/board')) {
      //   window.location.reload();
      //   return;
      // }
      navigate(`/v2/dwrapper/board?appId=${scanAppId}`);
    }
  };

  const headerRef = useRef<any>();
  const asideHiddenSpanRef = useRef<HTMLElement>(null);

  const asideRef = useRef<any>(null);

  const [headerUser, setHeaderUser] = useState<any>();

  // useEffect(() => {
  //   // if (location && location.state && location.state?.curProductInfo) {
  //   //   setCurProductInfo(location.state?.curProductInfo);
  //   // } else {
  //   //   // if (getProductInfoCookie()) {
  //   //   //   const productInfo = safelyParseJSON(getProductInfoCookie()!);
  //   //   //   setCurProductInfo(productInfo);
  //   //   // }
  //   // }

  // }, [location]);

  const getLatestAssmentUser = () => {
    let appId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.appId);
    productService
      .getProductInfo(appId)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.lastestAssessment) {
          setLatestAssessmentUserId(res.lastestAssessment?.userId);
        }
      });
  };

  useEffect(() => {
    const token = getCookie('idToken')!;
    const decodeds = jwt.decode(token);
    if (typeof decodeds === 'object' && decodeds !== null) {
      setHeaderUser({
        firstName: decodeds?.given_name,
        lastName: decodeds?.family_name,
      });
    }
  }, []);

  useEffect(() => {
    const pathName = location.pathname;
    const titleEle = document.querySelector('title')!;
    if (pathName.includes('/settings')) {
      titleEle.innerText = 'PwC Accessibility Checker';
      return;
    }
    if (pathName.includes('/issues')) {
      titleEle.innerText = 'PwC Accessibility Checker';
      return;
    }
    if (pathName.includes('/board')) {
      titleEle.innerText = 'PwC Accessibility Checker';
      return;
    }
    if (pathName.includes('/issuedetail')) {
      titleEle.innerText = 'PwC Accessibility Checker';
      return;
    }
    if (pathName === '/v2/') {
      titleEle.innerText = 'PwC Accessibility Checker';
      return;
    }
  }, [location]);

  const nameAbbrev = useMemo(() => {
    if (headerUser) return `${headerUser?.firstName.charAt(0) + headerUser?.lastName.charAt(0)}`;
  }, [headerUser]);

  const onClickLogo = () => {
    if (location.pathname === '/v2/') return;
    window.location.href = '/v2/';
  };

  useEffect(() => {
    if (!location.pathname.includes('/v2/dwrapper') && !location.pathname.includes('/v2/autoMatedScan')
      && !location.pathname.includes('/v2/manualIssue')) return;
    const productInfo = getProductInfoCookie();
    if (_pName) {
      setFinalProductname(_pName);
    } else if (productName) {
      setFinalProductname(productName);
    } else {
      if (productInfo) {
        setFinalProductname(safelyParseJSON(productInfo)?.name||safelyParseJSON(productInfo)?.name);
      }
    }
  }, [_pName]);

  useEffect(() => {
    const mode = localStorage.getItem('mode');
    if (mode) {
      setCurrentModeValue(mode === 'darkMode' ? 'darkMode' : 'lightMode');
      if (mode === 'darkMode') {
        document.body.setAttribute('data-mode', 'dark');
        setIsDarkMode && setIsDarkMode(true);
      } else {
        document.body.removeAttribute('data-mode');
        setIsDarkMode && setIsDarkMode(false);
      }
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/v2/dwrapper') {
      navigate('/v2/');
    }
  }, [location]);

  useEffect(() => {
    if (asideHiddenSpanRef && asideHiddenSpanRef.current) {
      asideHiddenSpanRef.current.focus();
    }
  }, [location, asideHiddenSpanRef]);

  const onLogoKeydownHandler = (event: any) => {
    if (event?.key?.toLowerCase() === 'enter') {
      onClickLogo();
    }
  };

  const showToaster = (
    appId: string,
    title: string,
    message?: string,
    status?: string,
    linkName?: string,
    productScanId?: string,
    withLoadingIcon = false,
    shouldAddReload = false
  ) => {
    if(!withLoadingIcon)
      toaster.notify(
        <Notification
          title={title}
          message={message}
          status={status}
          hyperLink={
            <a
              tabIndex={0}
              role="link"
              className="ap-notification-hyperlink ap-link height"
              onClick={() => goToRelatedLink(linkName ?? '', appId ?? '', productScanId ?? '', shouldAddReload)}
            >
              {linkName ?? ''}
            </a>
          }
        />,
        {
          position: 'bottomLeft',
          duration: 0,
          onClose: () => {},
        },
      );
    else
      toaster.notify(
        <Notification
          title={title}
          message={message}
          status={status}
          className={'notification-verificaiton'}
          icon={<Loading loadingType='circular' indeterminate={false} compact={false}></Loading>}
          hyperLink={
            <a
              tabIndex={0}
              role="link"
              className="ap-notification-hyperlink ap-link height"
              onClick={() => goToRelatedLink(linkName ?? '', appId ?? '', productScanId ?? '')}
            >
              {linkName ?? ''}
            </a>
          }
        />,
        {
          position: 'bottomLeft',
          duration: 0,
          onClose: () => {},
        },
      ); 
  };

  const reloadPage = () => {
    // Set a flag in sessionStorage
    sessionStorage.setItem('reloadFlag', 'true');
    
    // Reload the page
    window.location.reload();
  };

  useEffect(() => {
    // Check if the reload flag is set
    const reloadFlag = sessionStorage.getItem('reloadFlag');
    
    if (reloadFlag) {
      setCloseScanModalStatus && setCloseScanModalStatus(true);
      sessionStorage.removeItem('reloadFlag');
    }
  }, []);

  // const clickCloseElement = () => {
  //   setTimeout(() => {
  //     const notificationContents = document.querySelectorAll('.ap-notification-content');
  //     notificationContents.forEach(notificationContent => {
  //       // Check if the current element contains a child with the class name "ap-loading-circular loading"
  //       if (notificationContent.querySelector('.ap-loading-circular.loading')) {
  //         // Find the sibling element with the class name "ap-notification-icon-close"
  //           let sibling: any = notificationContent.nextElementSibling;
  //           while (sibling) {
  //             if (sibling.classList.contains('ap-notification-icon-close')) {
  //               // console.log(sibling); // Do something with the sibling element
  //               sibling?.click();
  //               break;
  //             }
  //           }
  //         }
  //       });
  //     }, 100);
  // }

  const goToRelatedLink = (name: string, appId: string, productScanId: string, shouldAddReload = false) => {
    let currentProductAppId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.appId);
    if (name && name.toLowerCase() === 'go to dashboard') {
      if (appId !== currentProductAppId) {
        navigate(`/v2/dwrapper/board?appId=${appId}`);
        window.location.reload();
      } else {
        if (location.pathname.includes('/v2/dwrapper/board')) {
          window.location.reload();
          return;
        }
        navigate(`/v2/dwrapper/board?appId=${appId}`);
        if(shouldAddReload) window.location.reload();
      }
    } else if (name && name.toLowerCase() === 'view errors') {
      if (appId !== currentProductAppId) {
        navigate(`/v2/dwrapper/settings?appId=${appId}`, {
          state: {
            activeTabIndex: 1,
            productId: productScanId,
          },
        });
        window.location.reload();
      } else {
        if (location.pathname.includes('/v2/dwrapper/settings')) {
          window.location.reload();
          return;
        }
        navigate(`/v2/dwrapper/settings/?appId=${appId}`, {
          state: {
            activeTabIndex: 1,
          },
        });
      }
    } else if (name && name.toLowerCase() === 'contact us for assistance') {
      window.open(
        'https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-qJtLN_BonZFrxiPNTnvCsRUNFUzWUwySEdIUjJWN1RXNkhENjYwUjhNNC4u',
        '_blank',
      );
    }  else if(name && name.toLowerCase() === 'view scan progress') {
      // navigate(`/v2/dwrapper/board?appId=${appId}`);
      // window.location.reload(); 
        navigate(`/v2/dwrapper/settings?appId=${appId}`, {
          state: {
            activeTabIndex: 1,
            productId: productScanId,
          },
        });
        reloadPage();
    }
  };

  const handleVerificationEvent = (websocketRes: any) => {
    const curProductId = websocketRes?.assessment?.productId;
    setCloseScanModalMethod && setCloseScanModalMethod(true);
    setProductId(curProductId);
    setProductNameVerification(websocketRes?.assessment?.productName);
    let getCompletedAssessmentSub: any;
    getCompletedAssessmentSub = assetService
        .getLatestAssessment(Number(curProductId))
        .pipe(
          take(1),
          switchMap((res: any) => {
            const assessmentId = res.id;
            if(websocketRes?.assessment?.id === assessmentId)
              return assetService.countdownPasscode(assessmentId.toString());
            else return of(null);
          })
        ).subscribe((res: any) => {
          if(!res) return;
          setAssetScanningErrorTime?.(res.data * 1000);//res.data
          const verifyEvent = () => {
            setAuthUser(websocketRes?.assessment?.authUser);//||websocketRes?.assessment?.userId
            setVerificationResultType?.(websocketRes?.type);
            getCompletedAssessmentSub?.unsubscribe();
            setShowVerificationCode?.(true);
          }
          if(showVerificationCode) {
            setShowVerificationCode?.(false);
            setTimeout(() => {
              verifyEvent();
            }, 200);
          } else {
            verifyEvent();
          }
        });
  }

  useEffect(() => {

    wsService.subscriber.next({
      event: 'assessmentError',
      value: (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId'),
    });
    wsService
      .listener('assessmentErrorMsg')
      .pipe(
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      ).subscribe(async (websocketRes) => {
        console.log('------new listener assessmentErrorMsg-------:', websocketRes);
      });


    let lastestAssessmentOfUserMsgSub: any;
    if(getCookie('user'))
    wsService.subscriber.next({
      event: 'lastestAssessmentOfUser',
      value: safelyParseJSON(decryptData(getCookie('user')||'')!)?.id,
    });

    lastestAssessmentOfUserMsgSub = wsService
      .listener('lastestAssessmentOfUserMsg')
      .pipe(
        catchError((error) => {
          console.log('error--ws--:', error);
          let title = `Technical issues prevented scanning this project.`;
          let message = 'Please try again later, and if the issue persists we would be happy to assist.';
          let status = 'error';
          let linkName = 'Contact us for assistance';
          showToaster('', title, message, status, linkName);
          setReScanBtnDisabled && setReScanBtnDisabled(false);
          return of(null);
        }),
      )
      .subscribe(async (websocketRes) => {
        console.log('------new listener-------:', websocketRes);
        const assessmentJobs = websocketRes?.assessment?.assessmentJobs;
        const currentProductId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
        if (assessmentJobs && websocketRes?.type === 'completed') {
          let userId = websocketRes?.assessment?.userId;
          let currentLoginUserId = safelyParseJSON(decryptData(getCookie('user')||'')!)?.id;

          if (userId === currentLoginUserId) {
            // from other page rerun the scan modal
            if (scanModalFrom && scanModalFrom === 'reRun') {
              if (closeScanModalStatus) {
                let failedJobs = assessmentJobs?.filter((job: any) => job.scanStatus < 0);
                let projectName = websocketRes?.assessment?.productName;
                if (failedJobs.length === 0) {
                  let title = `Scan complete for ${projectName}.`;
                  let message = `Check out the latest scan results on the product's dashboard.`;
                  let status = 'success';
                  let linkName = 'Go to dashboard';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                } else if (failedJobs.length === assessmentJobs.length) {
                  if(showVerificationCode) setShowVerificationCode?.(false);
                  let title = `Scan failed for ${projectName}.`;
                  let message = 'URLs provided were unreachable and presented errors.';
                  let status = 'error';
                  let linkName = 'View errors';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                } else if (failedJobs.length < assessmentJobs.length) {
                  let title = `Scan incomplete for ${projectName}.`;
                  let message = 'Some URLs were unreachable and presented errors.';
                  let status = 'warning';
                  let linkName = 'View errors';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                }
              } else {
                //if user not close the progress modal, and scan completed and modal closed automatically

                let failedJobs = assessmentJobs?.filter((job: any) => job.scanStatus < 0);
                let projectName = websocketRes?.assessment?.productName;
                if (failedJobs.length === 0) {
                  const currentProductId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
                  if (currentProductId !== websocketRes?.assessment?.productId) {
                    setTimeout(() => {
                      let title = `Scan complete for ${projectName}.`;
                      let message = `Check out the latest scan results on the product's dashboard.`;
                      let status = 'success';
                      let linkName = 'Go to dashboard';
                      showToaster(
                        websocketRes?.assessment?.appId,
                        title,
                        message,
                        status,
                        linkName,
                        websocketRes?.assessment?.productId,
                      );
                    }, 3000);
                  } else {
                    if (location.pathname === '/v2/') {
                      setTimeout(() => {
                        let title = `Scan complete for ${projectName}.`;
                        let message = `Check out the latest scan results on the product's dashboard.`;
                        let status = 'success';
                        let linkName = 'Go to dashboard';
                        showToaster(
                          websocketRes?.assessment?.appId,
                          title,
                          message,
                          status,
                          linkName,
                          websocketRes?.assessment?.productId,
                        );
                      }, 3000);
                    } else {
                      goToDashboardHandler(websocketRes?.assessment?.appId, websocketRes?.assessment?.productId);
                      setTimeout(() => {
                        let message = `Scan complete for ${projectName}.`;
                        showToaster(
                          websocketRes?.assessment?.appId,
                          '',
                          message,
                          'success',
                          '',
                          websocketRes?.assessment?.productId,
                        );
                      }, 3000);
                    }
                  }
                } else if (failedJobs.length === assessmentJobs.length &&
                  ['passcodeRequired', 'invalidPasscode'].indexOf(websocketRes?.type) === -1) {
                  let title = `Scan failed for ${projectName}.`;
                  let message = 'URLs provided were unreachable and presented errors.';
                  let status = 'error';
                  let linkName = 'View errors';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                } else if (failedJobs.length < assessmentJobs.length) {
                  let title = `Scan incomplete for ${projectName}.`;
                  let message = 'Some URLs were unreachable and presented errors.';
                  let status = 'warning';
                  let linkName = 'View errors';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                }
                // automatically close the modal
                setCloseScanModalMethod && setCloseScanModalMethod(true);
              }
            } else {
              // form automated page start scan modal
              if (closeScanModalStatus) {
                // if user close the progress modal
                if (location.pathname.includes('/autoMatedScan')) {
                  let failedJobs = assessmentJobs?.filter((job: any) => job.scanStatus < 0);
                  let projectName = websocketRes?.assessment?.productName;
                  if (failedJobs.length === 0) {
                    let title = `Scan complete for ${projectName}.`;
                    let message = `Check out the latest scan results on the product's dashboard.`;
                    let status = 'success';
                    let linkName = 'Go to dashboard';
                    showToaster(
                      websocketRes?.assessment?.appId,
                      title,
                      message,
                      status,
                      linkName,
                      websocketRes?.assessment?.productId,
                    );
                  } else if (failedJobs.length === assessmentJobs.length &&
                    ['passcodeRequired', 'invalidPasscode'].indexOf(websocketRes?.type) === -1) {
                    let title = `Scan failed for ${projectName}.`;
                    let message = 'URLs provided were unreachable and presented errors.';
                    let status = 'error';
                    let linkName = 'Contact us for assistance';
                    showToaster(
                      websocketRes?.assessment?.appId,
                      title,
                      message,
                      status,
                      linkName,
                      websocketRes?.assessment?.productId,
                    );
                  } else if (failedJobs.length < assessmentJobs.length) {
                    let title = `Scan incomplete for ${projectName}.`;
                    let message = 'Some URLs were unreachable and presented errors.';
                    let status = 'warning';
                    let linkName = 'Contact us for assistance';
                    showToaster(
                      websocketRes?.assessment?.appId,
                      title,
                      message,
                      status,
                      linkName,
                      websocketRes?.assessment?.productId,
                    );
                  }
                } else {
                  let failedJobs = assessmentJobs?.filter((job: any) => job.scanStatus < 0);
                  let projectName = websocketRes?.assessment?.productName;
                  if (failedJobs.length === 0) {
                    let title = `Scan complete for ${projectName}.`;
                    let message = `Check out the latest scan results on the product's dashboard.`;
                    let status = 'success';
                    let linkName = 'Go to dashboard';
                    showToaster(
                      websocketRes?.assessment?.appId,
                      title,
                      message,
                      status,
                      linkName,
                      websocketRes?.assessment?.productId,
                      false,
                      true
                    );
                  } else if (failedJobs.length === assessmentJobs.length && 
                    ['passcodeRequired', 'invalidPasscode'].indexOf(websocketRes?.type) === -1) {
                    let title = `Scan failed for ${projectName}.`;
                    let message = 'URLs provided were unreachable and presented errors.';
                    let status = 'error';
                    let linkName = 'View errors';
                    showToaster(
                      websocketRes?.assessment?.appId,
                      title,
                      message,
                      status,
                      linkName,
                      websocketRes?.assessment?.productId,
                    );
                  } else if (failedJobs.length < assessmentJobs.length) {
                    let title = `Scan incomplete for ${projectName}.`;
                    let message = 'Some URLs were unreachable and presented errors.';
                    let status = 'warning';
                    let linkName = 'View errors';
                    showToaster(
                      websocketRes?.assessment?.appId,
                      title,
                      message,
                      status,
                      linkName,
                      websocketRes?.assessment?.productId,
                    );
                  }
                }
              } else {
                //if user not close the progress modal, and scan completed and modal closed automatically
                // automatically close the modal
                setCloseScanModalMethod && setCloseScanModalMethod(true);
                let failedJobs = assessmentJobs?.filter((job: any) => job.scanStatus < 0);
                let projectName = websocketRes?.assessment?.productName;
                if (failedJobs.length === 0) {
                  goToDashboardHandler(websocketRes?.assessment?.appId, websocketRes?.assessment?.productId);
                  setTimeout(() => {
                    let message = `Scan complete for ${projectName}.`;
                    showToaster(
                      websocketRes?.assessment?.appId,
                      '',
                      message,
                      'success',
                      '',
                      websocketRes?.assessment?.productId,
                    );
                  }, 3000);
                } else if (failedJobs.length === assessmentJobs.length &&
                  ['passcodeRequired', 'invalidPasscode'].indexOf(websocketRes?.type) === -1) {
                  let title = `Scan failed for ${projectName}.`;
                  let message = 'URLs provided were unreachable and presented errors.';
                  let status = 'error';
                  let linkName = 'Contact us for assistance';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                } else if (failedJobs.length < assessmentJobs.length) {
                  let title = `Scan incomplete for ${projectName}.`;
                  let message = 'Some URLs were unreachable and presented errors.';
                  let status = 'warning';
                  let linkName = 'Contact us for assistance';
                  showToaster(
                    websocketRes?.assessment?.appId,
                    title,
                    message,
                    status,
                    linkName,
                    websocketRes?.assessment?.productId,
                  );
                }
              }
            }
          }
        } 
        else if (websocketRes?.assessment && websocketRes?.type === 'passcodeRequired') {
          handleVerificationEvent(websocketRes);
        } else if (websocketRes?.assessment && websocketRes?.type === 'invalidPasscode') {
          handleVerificationEvent(websocketRes);
        } else if (websocketRes?.assessment && websocketRes?.type === 'authSuccess') {
          setShowVerificationCode?.(false);
          // automatically close the modal
          setCloseScanModalMethod && setCloseScanModalMethod(true);
          setCloseScanModalStatus && setCloseScanModalStatus(true);
          let title = `Scanning ${websocketRes?.assessment?.productName}`;
          let message = 'We will notify you when the scan is completed.';
          let status = 'success';
          let linkName = 'View scan progress';
          setTimeout(() => {
            showToaster(
              websocketRes?.assessment?.appId,
              title,
              message,
              status,
              linkName,
              websocketRes?.assessment?.productId,
              true
            );
          }, 0);
        }
      });
    return () => {
      if (wsService) {
        wsService.unSubscriber('lastestAssessmentOfUser');
      }
      if (lastestAssessmentOfUserMsgSub) {
        lastestAssessmentOfUserMsgSub.unsubscribe();
      }
    };
  });

  useEffect(() => {
    let lastestAssessmentMsgSub: any;
    if(getProductInfoCookie())
    wsService.subscriber.next({
      event: 'lastestAssessment',
      value: (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId'),
    });

    lastestAssessmentMsgSub = wsService
      .listener('lastestAssessmentMsg')
      .pipe(
        catchError((error) => {
          let title = `Technical issues prevented scanning this project.`;
          let message = 'Please try again later, and if the issue persists we would be happy to assist.';
          let status = 'error';
          let linkName = 'Contact us for assistance';
          showToaster('', title, message, status, linkName);
          setReScanBtnDisabled && setReScanBtnDisabled(false);
          return of(null);
        }),
      )
      .subscribe(async (websocketRes: any) => {

        const assessmentJobs = websocketRes?.assessment?.assessmentJobs;
        if (assessmentJobs && websocketRes?.type === 'completed') {
          setReScanBtnDisabled && setReScanBtnDisabled(false);
          setLatestAssessmentResult && setLatestAssessmentResult(websocketRes);
        }
      });
    return () => {
      if (wsService) {
        wsService.unSubscriber('lastestAssessment');
      }
      if (lastestAssessmentMsgSub) {
        lastestAssessmentMsgSub.unsubscribe();
      }
      latestAssessmentRef.current?.unsubscribe();
    };
  });

  useEffect(() => {
    if (headerRef && headerRef.current) {
      const headerEle = headerRef.current as HTMLElement;
      const target = headerEle.querySelector('.ap-buttons-wrapper')?.children[0];
      target?.removeAttribute('tabindex');

      const pwcLogoPart = headerEle.querySelector('.ap-header-heading')?.children[0];
      pwcLogoPart?.setAttribute('tabindex', '0');
      pwcLogoPart?.addEventListener('keydown', onLogoKeydownHandler);
    }
  }, [headerRef]);

  const goMainContent = () => {
    let selector = '';
    if (location.pathname.includes('/issues')) {
      selector = '.issuesContainer';
    } else if (location.pathname.includes('/board')) {
      selector = '.dashboardContainer';
    } else if (location.pathname === '/v2/') {
      selector = '.home:not(.sideBarNavigation)';
    } else if (location.pathname.includes('/settings')) {
      selector = '.settingsPage';
    } else if (location.pathname.includes('/manualIssue')) {
      selector = '.manual9';
    } else if (location.pathname.includes('/getting-started')) {
      selector = '.ap-page-get-started';
    } else if (location.pathname.includes('/help-center/faq')) {
      selector = '.help-center-faq-content';
    } else if (location.pathname.includes('/help-center')) {
      selector = '.ap-page-help-center-welcome';
    } 

    if (location.pathname.includes('/manualIssue')) {
      const matchNodesArr = document.querySelectorAll(`${selector}`);
      if (window.innerWidth < 1440 || window.innerWidth === 1440) {
        matchNodesArr[1].scrollIntoView({
          behavior: 'smooth',
        });
        asideRef.current.blur();
        const element = matchNodesArr[1] as HTMLElement;
        element.focus();
      } else {
        matchNodesArr[0].scrollIntoView({
          behavior: 'smooth',
        });
        asideRef.current.blur();
        const element = matchNodesArr[0] as HTMLElement;
        element.focus();
      }
    } else {
      const element = document.querySelector(`${selector}`) as HTMLElement;
      element.scrollIntoView({
        behavior: 'smooth',
      });
      asideRef.current.blur();
      element.focus();
    }
  };

  const handlerAsideKeyDown = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === 'enter') {
      goMainContent();
    }
  };
  useEffect(() => {
    if (asideRef && asideRef.current) {
      asideRef.current?.addEventListener('keydown', handlerAsideKeyDown);
    }
    return () => {
      asideRef.current?.removeEventListener('keydown', handlerAsideKeyDown);
    };
  }, [asideRef, location]);

  const dropdownItemSelect = (value: any, item: any, event: React.SyntheticEvent) => {
    if (value === 'preferences') {
      setShowPreferenceModal(true);
    } else if (value === 'signout') {
      authService.logout(false);
    }
  };
  const needHelpDropdownItemSelect = (value: any, item: any, event: React.SyntheticEvent) => {
    if (value === 'submitFeedback') {
      // window.open('https://appkit.pwc.com/appkit4/content/help-center/feedback?issueScope=all&pageNum=1', '_blank');
      // window.open(
      //   'https://appkit.pwc.com/appkit4/content/help-center/feedback?tool=accessibility&version=angular',
      //   '_blank',
      // );
      window.open(
        'https://appkit.pwc.com/appkit4/content/help-center/feedback?tool=accessibility&newissue=true&version=angular',
        '_blank',
      );
    } else if (value === 'viewFAQ') {
      window.open('https://sites.google.com/pwc.com/accessibility-inclusive-design/faqs', '_blank');
    }
  };

  const onRadiosChange = (value: string, event: React.SyntheticEvent) => {
    setCurrentModeValue(value);
  };
  const onEmailToggleChange = (checked: boolean, event: React.SyntheticEvent) => {
    setEmailSwitchChecked((prev) => !prev);
  };

  const updatePreferencesBtnClick = () => {
    if (currentModeValue) {
      localStorage.setItem('mode', currentModeValue);
      if (currentModeValue === 'darkMode') {
        document.body.setAttribute('data-mode', 'dark');
        setIsDarkMode && setIsDarkMode(true);
      }
      if (currentModeValue === 'lightMode') {
        document.body.removeAttribute('data-mode');
        setIsDarkMode && setIsDarkMode(false);
      }
    }
    setShowPreferenceModal(false);
  };

  const preferenceModalContent = {
    title: 'Preferences',
    body: (
      <div className="header-preferenceModal-modalBody">
        <div className="header-preferenceModal-modalBody-radioPart">
          <h2>Interface appearance</h2>
          <div className="header-preferenceModal-modalBody-radioPart-radioGroup">
            <RadioGroup defaultValue={currentModeValue} name="interfaceAppearance" onChange={onRadiosChange}>
              <Radio value={'lightMode'}>Light mode</Radio>
              <Radio value={'darkMode'}>Dark mode</Radio>
            </RadioGroup>
          </div>
        </div>
      </div>
    ),
    footer: (
      <div className="header-preferenceModal-modalFooter">
        <div className="header-preferenceModal-modalFooter-btnArea">
          <Button
            onClick={() => {
              setShowPreferenceModal(false);
              updatePreferencesBtnClick();
            }}
          >
            Update preferences
          </Button>
        </div>
      </div>
    ),
  };

  // useEffect(() => {
  //   // Get the elements
  //   const headerHeadingDiv = headerRef.current.querySelector('.ap-header-heading');
  //   const backButtonPanel = headerRef.current.querySelector('.back-button-panel');

  //   // Check if elements exist
  //   if (headerHeadingDiv && backButtonPanel) {
  //     // Move the back-button-panel to be the first child of ap-header-heading
  //     headerHeadingDiv.insertAdjacentElement('afterbegin', backButtonPanel);
  //   }
  // }, []);

  return (
    <>
      <div className={'header-part-container'}>
        <span tabIndex={-1} ref={asideHiddenSpanRef} className="span-title" aria-hidden="true"></span>
        <a
          ref={asideRef}
          id="skip-link"
          role="link"
          onClick={goMainContent}
          tabIndex={0}
          className="skip-link skip-link-pc"
        >
          <span>Skip to main content</span>
        </a>
        <Header
          type="transparent"
          onClickLogo={onClickLogo}
          ariaLabel="PwC Accessibility Checker Homepage"
          ref={headerRef}
          titleTemplate={() => (
            <div className={'left'}>
              {/* {location.pathname !== '/v2/' && <div className="back-button-panel ap-mr-spacing-2 ap-mt-spacing-2">
                <a className="back-button" onClick={() => { navigate(`/v2/`) }}>
                  <span className="Appkit4-icon icon-arrow-left-outline ap-font-24" aria-label="back"></span>
                </a>
              </div>} */}
              <div className={'main-name'}>
                <span>Accessibility Checker</span>
                <Badge value={'Beta'} type={'primary-outlined'} />
              </div>
              {location.pathname === '/v2/' ? null : (
                <>
                  {finalProductname&&<div className={'divider'}></div>}
                  <div className={'productName'}>{finalProductname}</div>
                </>
              )}
            </div>
          )}
          userTemplate={() => (
            <div className={'header-part-container-right'}>
              {/* <div className="header-part-container-faq ">
                <DropdownButton
                  customTriggerNode={true}
                  customTriggerClassName="custom-node"
                  data={needHelpData}
                  prefixTemplate={(item) => <></>}
                  onSelect={needHelpDropdownItemSelect}
                >
                  <div className="header-part-container-faq-content" tabIndex={0}>
                    <span className="Appkit4-icon icon-help-question-outline"></span>
                    <span>Need help?</span>
                  </div>
                </DropdownButton>
              </div> */}
              <div className="header-avater">
                <DropdownButton
                  customTriggerNode={true}
                  customTriggerClassName="custom-node"
                  data={headerDropDownData}
                  prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
                  onSelect={dropdownItemSelect}
                >
                  <Avatar
                    tabIndex={0}
                    disabled={false}
                    // role="button"
                    label={nameAbbrev}
                    ariaLabel={`User Profile,${headerUser?.firstName} ${headerUser?.lastName}`}
                  />
                </DropdownButton>
              </div>
            </div>
          )}
        ></Header>
      </div>
      <CommonModal
        visible={showPreferenceModal}
        modalContent={preferenceModalContent}
        setVisibleFunc={setShowPreferenceModal}
        className=""
        bodyStyle={{}}
      />
      {showVerificationCode&&<VerificationModal authUser={authUser} productName={productNameVerification} productId={productId}></VerificationModal>}
    </>
  );
});

export default HeaderNew;
