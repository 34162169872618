import React from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@appkit4/react-components';
import classNames from 'classnames';

const Resource = () => {
  const navigate = useNavigate();
  const cardInfo: any[] = [
    {
      title: 'Issues and feedback',
      icon: 'comment',
      description: 'Submit issues, bugs & requests.',
      
    },
    {
      title: 'FAQs',
      icon: 'help-question',
      description: 'Find answers to your questions.',
      href: `/v2/help-center/faq`
    },
    {
      title: 'Videos',
      icon: 'play',
      description: 'Check Accessibility Checker tutorials.',
      href: `/v2/help-center/videos`,
      isComingSoon: true
    },
    {
      title: 'Documentation',
      icon: 'document-file',
      description: 'Access detailed how-to documentation.',
      href: '/v2/help-center/documentation',
      isComingSoon: true
    },
    {
      title: 'Release notes',
      icon: 'news-report',
      description: 'Check updates and new releases.',
      href: '/v2/help-center/release',
      isComingSoon: true
    }
  ];
  const onCardClick = (item: any) => {
    if(item.title === 'Issues and feedback') {
      window.open(
        'https://appkit.pwc.com/appkit4/content/help-center/feedback?tool=accessibility&newissue=true&version=angular',
        '_blank',
      );
      return;
    }
    navigate(item.href);
  };
  return (
    <div className="ap-page ap-page-help-center-welcome has-anchor">
      <div className="ap-page-content">
        <div className="ap-component-wrapper">
          <div className="ap-component-name help-center">
            Help center
          </div>
          <div className="ap-page-desc ap-mb-spacing-5">
            Welcome to Accessibility Checker help center. Select a card to quickly get the help you need.
          </div>
          <div className="ap-help-center-welcome-container">
            <div className="ap-help-center-welcome-card-wrapper">
              {cardInfo.map((item: any, i: number) => (
                <div
                  className={classNames("ap-help-center-welcome-card", {"coming-soon" : item.isComingSoon})}
                  key={i}
                  onClick={() => !item.isComingSoon && onCardClick(item)}
                  tabIndex={0}
                  onKeyDown={(e) => (e.key === 'Enter' && !item.isComingSoon) && onCardClick(item)}
                >
                  <div className="ap-help-center-welcome-card-header">
                    <div className="ap-help-center-welcome-card-header-title">
                      <span className="ap-help-center-welcome-card-header-title-text">{item.title}</span>
                      {item.subTitle && <span>{item.subTitle}</span>}
                      {item.isComingSoon && <Badge value="Coming soon" type={'info'} size="small" />}
                    </div>
                    {item.icon && (
                      <span className={`ap-help-center-welcome-card-header-icon Appkit4-icon icon-${item.icon}-outline`}></span>
                    )}
                    {item.showImg && <div className="ap-help-center-welcome-card-header-mask-icon"></div>}
                  </div>
                  <div className="ap-help-center-welcome-card-footer">
                    <span className="ap-help-center-welcome-card-footer-desc">{item.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;