import React, { useEffect, FC, useState, useMemo, useCallback, useRef, useContext } from 'react';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Button } from '@appkit4/react-components/button';
import ConformanceBar from '../ConformanceBar';
import { IDashboardType } from './types';
import { productService } from '@services';
import { Subscription, of, forkJoin } from 'rxjs';
import { take, switchMap, pluck, catchError, map } from 'rxjs/operators';
import { useSearchParams, useParams, json } from 'react-router-dom';
import LoadingComponent from '@components/Loading';
import { assetService } from '@services/asset.service';
import { issueService } from '@services/issue.service';
import moment from 'moment';
import './dashboard.scss';
import ScanPendingSvg from '@assets/icons/scanPending.svg';
import { useNavigate } from 'react-router-dom';
import ScanModal from '@components/ScanModal';
import ReRunScanSvg from '@assets/icons/RerunScan.svg';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON, uuid } from '@utils';
import { EntryContext } from '@layout/Entry';

const Dashboard: FC<IDashboardType> = (props) => {
  const {
    closeScanModalMethod,
    setCloseScanModalMethod,
    setCloseScanModalStatus,
    setReScanBtnDisabled,
    reScanBtnDisabled,
  } = useContext(EntryContext);
  const navigate = useNavigate();
  const [score, setScore] = useState('0');
  const [search] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [criticalNum, setCriticalNum] = useState<any>();
  const [seriousNum, setSeriousNum] = useState<any>();
  const [moderateNum, setModerateNum] = useState<any>();
  const [minorNum, setMinorNum] = useState<any>();

  // const [criticalDetailData, setCriticalDetailData] = useState([]);
  // const [seriousDetailData, setSeriousDetailData] = useState([]);
  // const [moderateDetailData, setModerateDetailData] = useState([]);
  // const [minorDetailData, setMinorDetailData] = useState([]);

  const [assessmentId, setAssessmentId] = useState<any>();
  const [manualRatio, setManualRation] = useState<any>('');
  const [lastRunDate, setLastRunDate] = useState('');
  const [lastUpdateDate, setLastUpdateDate] = useState('');

  const [lastScanTime, setLastScanTime] = useState('');

  const [curProductInfo, setCurProductInfo] = useState<any>([]);
  const [curProductId, setCurProductId] = useState<any>('');

  const [showScanModal, setShowScanModal] = useState(false);
  const [isAssetAssessmentScanning, setIsAssetAssessmentScanning] = useState(false);
  const autoScanTblDataRef = useRef<any[]>([]);
  const [autoScanedUrls, setAutoScanedUrls] = useState<any[]>([]);

  const [dashboardPageIssueNumObj, setDashboardPageIssueNumObj] = useState<any>();
  const [hasCalled, setHasCalled] = useState(true);
  const [trigger, setTrigger] = useState(0);
  // const localStorageProductInfo = useMemo(() => {
  //   const temp = getProductInfoCookie();
  //   return temp && safelyParseJSON(temp);
  // }, []);
  // useEffect(() => {
  //   if(closeScanModalMethod === true) {
  //     setIsLoading(false);
  //     setReScanBtnDisabled?.(false);
  //   }
  // }, [closeScanModalMethod])

  useEffect(() => {
    if (reScanBtnDisabled === false && !hasCalled) {
      reload();
      setHasCalled(true);
    } else if (reScanBtnDisabled === true) {
      setHasCalled(false);
    }
  }, [reScanBtnDisabled, hasCalled]);

  useEffect(() => {
    const appId = search.get('appId');
    let v2AccessRef: Subscription;
    if (appId) {
      v2AccessRef = productService
        .v2GetPrivateAssetMemberDetail(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.status === 1 && !res?.isMember) {
            navigate('/v2/forbidden', {
              state: {
                origin: 'dashboard',
                productName: res?.name,
                ownerEmail: res?.ownerName,
              },
            });
          }
        });
    }
    return () => {
      v2AccessRef?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    let resSubs: any;
    if (curProductId) {
      resSubs = assetService
        .getAssetUrls(curProductId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.status === 1) {
            setAutoScanedUrls(res.data);
            autoScanTblDataRef.current = res.data.map((url: any, idx: number) => ({
              ...url,
              id: idx + 1,
              pageTitle: url?.nickName,
              url: url?.siteUrl,
              scanStatus: url?.scanStatus,
              action: 'Delete',
            }));
          }
        });
    }

    return () => {
      resSubs?.unsubscribe();
    };
  }, [curProductId, trigger]);

  /**
   * Get the progress bar score
   */

  // useEffect(() => {
  //   let scoreSub: Subscription;
  //   setIsLoading(true);
  //   if (curProductId) {
  //     scoreSub = productService
  //       .getScore(curProductId)
  //       .pipe(take(1))
  //       .subscribe((res) => {
  //         if (res) {
  //           setScore(res?.percentage?.toString()?.split('.')[0]);
  //         }
  //       });
  //   }
  //   return () => {
  //     if (scoreSub) scoreSub.unsubscribe();
  //   };
  // }, [curProductId]);

  useEffect(() => {
    setIsLoading(true);
    const appId = search.get('appId');
    let manualScoreSub: Subscription;
    if (appId) {
      manualScoreSub = productService
        .getProductInfo(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            setCurProductInfo(res);
            setCurProductId(res?.id);
            setScore(res?.score?.split('.')[0]);
          }
        });
    }
    return () => {
      if (manualScoreSub) manualScoreSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let getCompletedAssessmentSub: Subscription;
    if (curProductId) {
      getCompletedAssessmentSub = assetService
        .getLatestAssessment(curProductId)
        .pipe(
          take(1),
          switchMap((res) => {
            const assessmentId = res.id;
            setLastScanTime(res?.lastScanTime);
            setAssessmentId(assessmentId);
            return issueService.getIssuesFilters(curProductId, assessmentId);
          }),
          take(1),
          pluck('filters', 'impact'),
        )
        .subscribe((res) => {
          if (res) {
            // const critical = res.filter((item: any) => item.name === 'Critical')[0];
            // const serious = res.filter((item: any) => item.name === 'Serious')[0];
            // const moderate = res.filter((item: any) => item.name === 'Moderate')[0];
            // const minor = res.filter((item: any) => item.name === 'Minor')[0];
            // setCriticalNum(critical.count ? critical.count : 0);
            // setSeriousNum(serious.count ? serious.count : 0);
            // setModerateNum(moderate.count ? moderate.count : 0);
            // setMinorNum(minor.count ? minor.count : 0);
          }
        });
    }
    return () => {
      if (getCompletedAssessmentSub) {
        getCompletedAssessmentSub.unsubscribe();
      }
    };
  }, [curProductId, trigger]);

  /**
   * get critical, seriou, moderate, minor count
   */

  // useEffect(() => {
  //   let getDataSub: Subscription;
  //   if (assessmentId && curProductId) {
  //     getDataSub = forkJoin([
  //       issueService.getFilteredIssuesByPage({
  //         productId: curProductId,
  //         assessmentId: assessmentId,
  //         impact: ['Critical'],
  //       }),
  //       issueService.getFilteredIssuesByPage({
  //         productId: curProductId,
  //         assessmentId: assessmentId,
  //         impact: ['Serious'],
  //       }),
  //       issueService.getFilteredIssuesByPage({
  //         productId: curProductId,
  //         assessmentId: assessmentId,
  //         impact: ['Moderate'],
  //       }),
  //       issueService.getFilteredIssuesByPage({
  //         productId: curProductId,
  //         assessmentId: assessmentId,
  //         impact: ['Minor'],
  //       }),
  //     ])
  //       .pipe(
  //         map((item: any) => {
  //           return item.map((child: any) => child.filters.assessment);
  //         }),
  //         catchError((error: any) => {
  //           console.error(error);
  //           return of(null);
  //         }),
  //       )
  //       .subscribe((res) => {
  //         setCriticalDetailData(res[0] ? res[0] : '');
  //         setSeriousDetailData(res[1] ? res[1] : '');
  //         setModerateDetailData(res[2] ? res[2] : '');
  //         setMinorDetailData(res[3] ? res[3] : '');
  //       });
  //   }

  //   return () => {
  //     if (getDataSub) {
  //       getDataSub.unsubscribe();
  //     }
  //   };
  // }, [assessmentId, curProductId]);

  useEffect(() => {
    setIsLoading(true);
    let getDashboardPageIssueNumSub: Subscription;

    if (curProductId) {
      if (assessmentId) {
        getDashboardPageIssueNumSub = issueService
          .getDashboardPageIssueNum({ productId: curProductId, assessmentId: assessmentId })
          .subscribe((res) => {
            if (res.status === 1 && res?.detail) {
              const detail = res?.detail;
              setDashboardPageIssueNumObj(res?.detail);
              setCriticalNum(Number(detail?.autoCritical) + Number(detail?.manualCritical));
              setSeriousNum(Number(detail?.autoSerious) + Number(detail?.manualSerious));
              setModerateNum(Number(detail?.autoModerate) + Number(detail?.manualModerate));
              setMinorNum(Number(detail?.autoMinor) + Number(detail?.manualMinor));
            }
            if (res?.reviewDate) {
              const assessDateString = moment(res?.reviewDate).format('LL');
              setLastUpdateDate(assessDateString);
            }
            setIsLoading(false);
          });
      } else {
        setDashboardPageIssueNumObj({
          autoCritical: 0,
          autoSerious: 0,
          autoModerate: 0,
          autoMinor: 0,
          manualCritical: 0,
          manualSerious: 0,
          manualModerate: 0,
          manualMinor: 0,
        });
      }
    }
    return () => {
      getDashboardPageIssueNumSub?.unsubscribe();
    };
  }, [assessmentId, curProductId, trigger]);

  const finalScore = useMemo(() => {
    if (['0', undefined, null].includes(score)) {
      return 0;
    } else {
      return Number(score?.split('.')[0]);
    }
  }, [score, trigger]);

  const innerColor = useMemo(() => {
    return finalScore >= 85 ? '#21812D' : '#C52A1A';
  }, [finalScore, trigger]);

  useEffect(() => {
    let getManualCheckForDashboardSub: Subscription;
    if (curProductId) {
      getManualCheckForDashboardSub = issueService
        .getManualCheckForDashboard({ productId: curProductId })
        .subscribe((res) => {
          setManualRation(res?.manualcheckStatus);
        });
    }

    return () => {
      getManualCheckForDashboardSub?.unsubscribe();
    };
  }, [curProductId, trigger]);

  const recommandInfo = useMemo(() => {
    if (!lastScanTime) {
      return {
        needToolTip: false,
        title: 'Get your WCAG 2.2. conformance',
        titleDesc:
          'Scan and learn how to enhance your product’s accessibility compliance by running an automated scan. ',
        btnName: '',
        iconName: '',
        lightDesc: '',
        tipContent: '',
      };
    }

    if (!manualRatio) {
      return {
        needToolTip: true,
        title: 'Conduct a manual review',
        titleDesc:
          'The automated scan is just one step in the accessibility assessment process. Ensure a thorough evaluation by performing a manual review.',
        btnName: 'Go to manual review',
        iconName: 'icon-survey-checkmark-outline',
        lightDesc:
          'During sprint planning, discuss with your development team the potential to address similar high and low priority issues together.',
        tipContent:
          "The manual review is a detailed checklist of WCAG 2.2 requirements for a comprehensive evaluation of your product's accessibility compliance.",
      };
    }
    if (manualRatio) {
      if (manualRatio === '0') {
        return {
          needToolTip: true,
          title: 'Conduct a manual review',
          titleDesc:
            'The automated scan is just one step in the accessibility assessment process. Ensure a thorough evaluation by performing a manual review.',
          btnName: 'Go to manual review',
          iconName: 'icon-survey-checkmark-outline',
          lightDesc:
            'During sprint planning, discuss with your development team the potential to address similar high and low priority issues together.',
          tipContent:
            "The manual review is a detailed checklist of WCAG 2.2 requirements for a comprehensive evaluation of your product's accessibility compliance.",
        };
      }
      if (Number(manualRatio) === 1) {
        return {
          needToolTip: true,
          title: 'Finish the manual review',
          titleDesc: `The manual review is still pending completion. To advance your conformance progression, please ensure you finalize the full assessment.`,
          btnName: 'Go to manual review',
          lightDesc: 'Rerun the automated scan if need to add new URLs, features, or after implementing fixes.',
          iconName: 'icon-survey-checkmark-outline',
          tipContent:
            "The manual review is a detailed checklist of WCAG 2.2 requirements for a comprehensive evaluation of your product's accessibility compliance.",
        };
      }
      if (Number(manualRatio) === 2) {
        if (criticalNum === 0 && seriousNum === 0 && (moderateNum !== 0 || minorNum !== 0)) {
          return {
            title: 'Fix low priority issues',
            titleDesc:
              'Create a roadmap to address and resolve low priority issues to achieve 100% conformance with WCAG 2.2 requirements.',
            btnName: 'See low priority issues',
            lightDesc: 'Rerun the automated scan if need to add new URLs, features, or after implementing fixes.',
            iconName: '',
            needToolTip: false,
            tipContent: '',
          };
        }

        if (criticalNum === 0 && seriousNum === 0 && moderateNum === 0 && minorNum === 0) {
          return {
            needToolTip: true,
            title: 'Rerun automated scan as needed',
            titleDesc: 'Rerun the automated scan if you need to add new URLs, features, or pages to your product.',
            btnName: 'Run automated scan',
            iconName: 'icon-analysis-outline',
            lightDesc: '',
            tipContent:
              'The automated scan evaluates your product against WCAG 2.2 requirements, providing a solid starting point to identify areas for improvement.',
          };
        }

        return {
          title: 'Fix high priority issues',
          titleDesc: finalScore < 85 ? 'Prioritize the resolution of high priority issues and aim to achieve a conformance level of at least 85% before product release.' :
         `You're almost there! Prioritize addressing all high priority issues before product launch.`,
          btnName: 'See high priority issues',
          iconName: '',
          needToolTip: false,
          lightDesc:
            'During sprint planning, discuss with your development team the potential to address similar high and low priority issues together.',
          tipContent: '',
        };
      }
    }
  }, [manualRatio, criticalNum, seriousNum, moderateNum, minorNum, lastScanTime, trigger]);

  // const getRiskLevelData = (risk: string) => {
  //   const getAutoAndManualData = (data: any[]) => {
  //     const autoData = data.filter((item: any) => item.name === 'auto')[0];
  //     const manualData = data.filter((item: any) => item.name === 'manual')[0];
  //     return { autoCnt: autoData?.count, manualCnt: manualData?.count };
  //   };
  //   switch (risk) {
  //     case 'Critical':
  //       return getAutoAndManualData(criticalDetailData);
  //     case 'Serious':
  //       return getAutoAndManualData(seriousDetailData);
  //     case 'Moderate':
  //       return getAutoAndManualData(moderateDetailData);
  //     case 'Minor':
  //       return getAutoAndManualData(minorDetailData);
  //     default:
  //       return {};
  //   }
  // };

  /**
   * Get the conformance area two time
   */

  useEffect(() => {
    let timeLabelSub: Subscription;
    if (curProductId) {
      timeLabelSub = productService
        .getSummary(curProductId)
        .pipe(
          take(1),
          catchError((err) => {
            if (err) return of(err);
            return of(null);
          }),
        )
        .subscribe((res) => {
          const { lastAutoScan } = res;
          if (lastAutoScan) {
            const lastAutoScanString = moment(lastAutoScan).format('LL');
            setLastRunDate(lastAutoScanString);
          }

          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        });
    }
    return () => {
      if (timeLabelSub) timeLabelSub.unsubscribe();
    };
  }, [curProductId, trigger]);

  // const lastUpdateStr = useMemo(() => {
  //   if (manualRatio) {
  //     if (lastUpdateDate) {
  //       return `Last update ${lastUpdateDate}`;
  //     }
  //     return '';
  //   }
  //   return 'Review not started.';
  // }, [manualRatio, lastUpdateDate]);

  const goToAutomaticScanPage = () => {
    navigate('/v2/autoMatedScan', {
      state: {
        origin: 'dashboard',
        curProductInfo,
      },
    });
  };
  const goToManualReviewPage = () => {
    navigate('/v2/manualIssue', {
      state: {
      origin: 'dashboard'
    }});
  };

  const goToIssuesPage = () => {
    navigate(`/v2/dwrapper/issues?appId=${curProductInfo?.appId}`, {
      state: {
        origin: 'dashboard',
        curProductInfo,
      },
    });
  };

  const reBtnClickHandler = () => {
    const btnName = recommandInfo?.btnName;
    switch (btnName) {
      case 'See high priority issues':
        goToIssuesPage();
        break;
      case 'See low priority issues':
        goToIssuesPage();
        break;
      case 'Run automated scan':
        setShowScanModal(true);
        setCloseScanModalMethod && setCloseScanModalMethod(false);
        setCloseScanModalStatus && setCloseScanModalStatus(false);
        localStorage.setItem('scanModalFrom', 'reRun');
        break;
      case 'Go to manual review':
        goToManualReviewPage();
        break;
      default:
        console.log('-reBtnClickHandler-');
    }
  };

  const loadingBtn = <Button
    compact={false}
    kind="secondary"
    disabled={true}
    loading={true}
  >
  </Button>;
  const btnClickEvent = () => {
    autoScanTblDataRef.current = [
      ...autoScanTblDataRef.current?.map((ele) => ({ ...ele, scanStatus: 'Pending...' })),
    ];
    setShowScanModal(true);
    setCloseScanModalMethod && setCloseScanModalMethod(false);
    setCloseScanModalStatus && setCloseScanModalStatus(false);
  }
  const conformancePartContent = useMemo(() => {
    // return score ? (
    return lastScanTime ? (
      <>
        <div className="col-7 rowStyle">
          <div className="conformanceBarArea">
            <ConformanceBar
              score={finalScore}
              minimumRequiredPercent={85}
              barWidth={597}
              backgroundColorOuter={'#D1D1D1'}
              backgroundColorInner={innerColor}
            />
          </div>
        </div>
        <div className="col-5 rowStyle">
          <div className={'function-btn-area'}>
            <div className={'function-area-btn-first'}>
            {reScanBtnDisabled?
              loadingBtn :
              <Button
                compact={false}
                kind="secondary"
                icon="icon-analysis-outline"
                onClick={btnClickEvent}
              >
                Run automated scan
              </Button>}
              <span className={'text'}>{lastRunDate ? `Last scan ${lastRunDate}.` : 'Not started'}</span>
            </div>
            <div className={'function-area-btn-last'}>
              <Button
                compact={false}
                kind="secondary"
                icon="icon-survey-checkmark-outline"
                onClick={goToManualReviewPage}
              >
                Go to manual review
              </Button>
              <span className={'text'}>
                {manualRatio && lastUpdateDate ? `Last update ${lastUpdateDate}.` : 'Review not started.'}
              </span>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className="conformancePartContent-productPendingScanPage">
        <div className="col-7 rowStyle normal">
          <div className="conformancePartContent-productPendingScanPage-textArea">
            <h2>Product pending scan.</h2>
            <span className="conformancePartContent-productPendingScanPage-textArea-span">
              Set up an automated scan and begin your journey to accessible and inclusive experiences.
            </span>
            <div className="conformancePartContent-productPendingScanPage-textArea-btnArea">
              <Button kind="primary" onClick={goToAutomaticScanPage}>
                Set up automated scan
              </Button>
            </div>
          </div>
        </div>
        <div className="col-5 rowStyle normal">
          <div className="conformancePartContent-productPendingScanPage-imgArea">
            <img src={ScanPendingSvg} alt="scan pending logo" />
          </div>
        </div>
        <div className="conformancePartContent-productPendingScanPage-textArea responsive">
          <h2>Product pending scan.</h2>
          <span className="conformancePartContent-productPendingScanPage-textArea-span">
            Set up an automated scan and begin your journey to accessible and inclusive experiences.
          </span>
          <div className="conformancePartContent-productPendingScanPage-textArea-btnArea">
            <Button kind="primary" onClick={goToAutomaticScanPage}>
              Set up automated scan
            </Button>
          </div>
        </div>
        <div className="conformancePartContent-productPendingScanPage-imgArea responsive">
          <img src={ScanPendingSvg} alt="scan pending logo" />
        </div>
      </div>
    );
  }, [lastScanTime, finalScore, innerColor, lastRunDate, manualRatio, lastUpdateDate, reScanBtnDisabled, trigger]);

  const reload = () => {
    const appId = search.get('appId');
    if (appId) {
      productService
        .getProductInfo(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            setCurProductInfo(res);
            setCurProductId(res?.id);
            setScore(res?.score?.split('.')[0]);
            setTrigger(prev => prev + 1);
          }
        });
    }
  };

  const gethighPritoryIssuesCnt = () => {
    if (dashboardPageIssueNumObj) {
      const { autoCritical, autoSerious, manualCritical, manualSerious } = dashboardPageIssueNumObj;
      return autoCritical + autoSerious + manualCritical + manualSerious;
    }
  };
  const getLowPritoryIssuesCnt = () => {
    if (dashboardPageIssueNumObj) {
      const { autoModerate, manualModerate, autoMinor, manualMinor } = dashboardPageIssueNumObj;
      return autoModerate + manualModerate + autoMinor + manualMinor;
    }
  };

  return (
    <>
      <div tabIndex={-1} className={`${isLoading ? 'dashboardContainerLoading' : 'dashboardContainer'}`}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="row dashboard-label-row">
              <h1 className="headerTitle">Dashboard</h1>
            </div>
            <div className={`row wcagRow`}>
              <div className="wcagLabelTOoltipContainer">
                <h2 className="label">WCAG 2.2 conformance</h2>
                <Tooltip
                  trigger="hover"
                  position="top"
                  distance={4}
                  id="tooltipDesc"
                  appendAfterTarget={true}
                  hideTooltipOnBlur={true}
                  content={
                    'WCAG 2.2 conformance measures the level to which your product meets the standard accessibility guidelines, ensuring usability for all users, regardless of disabilities.'
                  }
                >
                  <span
                    tabIndex={0}
                    // role="buttons"
                    className={`Appkit4-icon icon-information-fill appkitIcon-color`}
                  ></span>
                </Tooltip>
              </div>
            </div>
            <div className={`row rowStyle conformanceRow`}>{conformancePartContent}</div>

            <div className="row highRecommandContentRow rowStyle">
              <div className="col-7 rowStyle">
                <div className="highLabelArea">
                  <h2 className={'label'}>High priority issues ({gethighPritoryIssuesCnt()})</h2>
                  <Tooltip
                    trigger="hover"
                    position="top"
                    distance={4}
                    id="tooltipHigh"
                    appendAfterTarget={true}
                    hideTooltipOnBlur={true}
                    content="High priority issues include Critical and Serious issues. Both must be fixed before product release."
                  >
                    <span tabIndex={0} className={`Appkit4-icon icon-information-fill appkitIcon-color`}></span>
                  </Tooltip>
                </div>
                <div className="highContentArea">
                  <div className="criticalSeriousPart">
                    <div className={'critical'}>
                      <h3 className={'text'}>Critical</h3>
                      <div className={'numbertext'}>
                        {dashboardPageIssueNumObj?.autoCritical + dashboardPageIssueNumObj?.manualCritical}
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-analysis-outline ${'icon-space'}`}></span>
                        <span className={'automatedscan-text'}>Automated scan:</span>
                        <span className={'left-numbertext'}>
                          {/* {lastScanTime ? (getRiskLevelData('Critical') as any).autoCnt : 'Not scanned'} */}
                          {lastScanTime ? dashboardPageIssueNumObj?.autoCritical : 'Not scanned'}
                        </span>
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-survey-checkmark-outline ${'icon-space'}`}></span>
                        <span className={'manualreview-text'}>Manual review:</span>
                        <span className={'left-numbertext'}>
                          {manualRatio && (Number(manualRatio) === 1 || Number(manualRatio) === 2)
                            ? dashboardPageIssueNumObj?.manualCritical
                            : 'Not started'}
                        </span>
                      </div>
                    </div>
                    <div className={'serious'}>
                      <h3 className={'text'}>Serious</h3>
                      <div className={'numbertext'}>
                        {dashboardPageIssueNumObj?.autoSerious + dashboardPageIssueNumObj?.manualSerious}
                      </div>
                      <div className={'text-container'}>
                        <span className={'automatedscan-text'}>
                          <span className={`Appkit4-icon icon-analysis-outline icon-space`}></span>
                          <span>Automated scan:</span>
                          <span className={'left-numbertext'}>
                            {lastScanTime ? dashboardPageIssueNumObj?.autoSerious : 'Not scanned'}
                          </span>
                        </span>
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-survey-checkmark-outline ${'icon-space'}`}></span>
                        <span className={'manualreview-text'}>Manual review:</span>
                        <span className={'left-numbertext'}>
                          {manualRatio && (Number(manualRatio) === 1 || Number(manualRatio) === 2)
                            ? dashboardPageIssueNumObj?.manualSerious
                            : 'Not started'}
                        </span>
                      </div>
                    </div>
                  </div>
                  {finalScore < 85 && (
                    <div className={'high-desc'}>
                      <span className={'highDescContainer'}>
                        <span className={`Appkit4-icon icon-lightbulb-outline outlineIcon`}></span>
                        <span>Prioritize all high priority issues prior to product launch.</span>
                      </span>
                    </div>
                  )}
                </div>
                <div className="lowPart">
                  <div className="lowLabelArea">
                    <h2 className={'label'}>Low priority issues ({getLowPritoryIssuesCnt()})</h2>
                    <Tooltip
                      trigger="hover"
                      position="top"
                      distance={4}
                      id="tooltipLow"
                      appendAfterTarget={true}
                      hideTooltipOnBlur={true}
                      content="Low priority issues encompass Moderate and Minor issues. While not mandatory to fix before release, they
                  can be added to the roadmap for future improvements."
                    >
                      <span tabIndex={0} className={`Appkit4-icon icon-information-fill appkitIcon-color`}></span>
                    </Tooltip>
                  </div>
                  <div className="lowContentArea">
                    <div className={'moderate'}>
                      <h3 className={'text'}>Moderate</h3>
                      <div className={'numbertext'}>
                        {dashboardPageIssueNumObj?.autoModerate + dashboardPageIssueNumObj?.manualModerate}
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-analysis-outline icon-space`}></span>
                        <span className={'automatedscan-text'}>Automated scan:</span>
                        <span className={'left-numbertext'}>
                          {lastScanTime ? dashboardPageIssueNumObj?.autoModerate : 'Not scanned'}
                        </span>
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-survey-checkmark-outline icon-space`}></span>
                        <span className={'manualreview-text'}>Manual review:</span>
                        <span className={'left-numbertext'}>
                          {manualRatio && (Number(manualRatio) === 1 || Number(manualRatio) === 2)
                            ? dashboardPageIssueNumObj?.manualModerate
                            : 'Not started'}
                        </span>
                      </div>
                    </div>
                    <div className={'minor'}>
                      <h3 className={'text'}>Minor</h3>
                      <div className={'numbertext'}>
                        {dashboardPageIssueNumObj?.autoMinor + dashboardPageIssueNumObj?.manualMinor}
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-analysis-outline icon-space`}></span>
                        <span className={'automatedscan-text'}>Automated scan:</span>
                        <span className={'left-numbertext'}>
                          {lastScanTime ? dashboardPageIssueNumObj?.autoMinor : 'Not scanned'}
                        </span>
                      </div>
                      <div className={'text-container'}>
                        <span className={`Appkit4-icon icon-survey-checkmark-outline icon-space'`}></span>
                        <span className={'manualreview-text'}>Manual review:</span>
                        <span className={'left-numbertext'}>
                          {manualRatio && (Number(manualRatio) === 1 || Number(manualRatio) === 2)
                            ? dashboardPageIssueNumObj?.manualMinor
                            : 'Not started'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 rowStyle">
                <div className="recommandLabelArea">
                  <h2 className={'title'}>Recommended actions</h2>
                </div>
                <div className="recommandContentArea">
                  <div className="recommTooltip">
                    <h3 className={'label'}>{recommandInfo?.title}</h3>
                    {recommandInfo?.needToolTip && (
                      <Tooltip
                        trigger="hover"
                        position="top"
                        distance={4}
                        id="tooltipRecomended"
                        appendAfterTarget={true}
                        hideTooltipOnBlur={true}
                        content={recommandInfo?.tipContent}
                      >
                        <span tabIndex={0} className={`Appkit4-icon icon-information-fill appkitIcon-color`}></span>
                      </Tooltip>
                    )}
                  </div>
                  <div className={'desc'}>{recommandInfo?.titleDesc}</div>
                  {recommandInfo?.btnName ? (
                    <div className={'reBtn-container'}>
                      <Button
                        kind="primary"
                        icon={recommandInfo?.iconName ? recommandInfo?.iconName : ''}
                        onClick={reBtnClickHandler}
                      >
                        {recommandInfo?.btnName}
                      </Button>
                    </div>
                  ) : null}

                  {recommandInfo?.lightDesc && (
                    <div className={'reco-desc'}>
                      <span className={'recomDescContainer'}>
                        <span className={`Appkit4-icon icon-lightbulb-outline recoDescIcon`}></span>
                        <span>{recommandInfo?.lightDesc}</span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {getProductInfoCookie() && <ScanModal
        curProductInfo={safelyParseJSON(getProductInfoCookie()!)}
        footer={<></>}
        visible={showScanModal && !closeScanModalMethod}
        setVisibleFunc={setShowScanModal}
        className="reRunScanModal"
        title="Confirmation"
        imgSrc={ReRunScanSvg}
        boldText="Confirm the pages to scan."
        descText="Ensure manual review issues are still applicable if adding or removing pages to scan. "
        originTableData={autoScanTblDataRef.current}
        isAssetAssessmentScanning={isAssetAssessmentScanning}
        setIsAssetAssessmentScanning={setIsAssetAssessmentScanning}
        origin="reRun"
        reload={reload}
        uuid={uuid()}
        needGetAssetUrls={true}
      />}
    </>
  );
};

export default Dashboard;
