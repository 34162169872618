import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import './index.scss';
import { Steppers, Stepper } from '@appkit4/react-components/stepper';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { IStepper } from './types';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import { Table, Column } from '@appkit4/react-components/table';
import { Pagination } from '@appkit4/react-components/pagination';
import FooterPage from '@components/Footer';
import { TextArea } from '@appkit4/react-components/field';
import CommonModal from '@components/modals/CommonModal';
import { authenticationTypesData, idamOrIamExpandData } from './constants';
import LoggedOutSvg from '@assets/icons/LoggedOut.svg';
import ScanInProgressSvg from '@assets/icons/scanInProgress.svg';
import ConsultingCyberSecuritySvg from '@assets/icons/ConsultingCyberSecurity.svg';
import UpdatedPasswordUpdatedSvg from '@assets/icons/UpdatedPasswordUpdated.svg';
import { CheckboxGroup, Checkbox } from '@appkit4/react-components/checkbox';
import ScanModal from '@components/ScanModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { getClickableLink, validateUrlForAutoScan } from '@utils';
import _ from 'lodash';
import { assetService } from '@services/asset.service';
import { take, concatMap, switchMap, filter, map } from 'rxjs/operators';
import { Subscription, of, zip } from 'rxjs';
import { productService } from '@services';
import { axios } from '@services/axios.service';
import { Notification, toaster } from '@appkit4/react-components/notification';
import LoadingComponent from '@components/Loading';
import { wsService } from '@services/websocket.service';
import { catchError } from 'rxjs/operators';
import { decryptData, getCookie, getProductInfoCookie, mergeSameEleInTwoArrays, safelyParseJSON, uuid } from '@utils/common';
import { Accordion, AccordionItem } from '@appkit4/react-components/accordion';
import { EntryContext } from '@layout/Entry';

const steppersInitialData = [
  { label: 'Pages to scan', value: 'pagesToScan', status: 'normal' },
  { label: 'Access requirements', value: 'accessRequirements', status: 'normal' },
  { label: 'Review and scan', value: 'reviewAndScan', status: 'normal' },
];

const AutoMatedScanPage = () => {
  const { setCloseScanModalStatus, setCloseScanModalMethod, closeScanModalMethod } = useContext(EntryContext);
  const [steppersData, setSteppersData] = useState<IStepper[]>(steppersInitialData);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [pageUrlInputVal, setPageUrlInputVal] = useState('');
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showStepperModal, setShowStepperModal] = useState(false);
  const [mustLogOptionClicked, setMustLogOptionClicked] = useState(false);
  const [allCanAccessOptionClicked, setAllCanAccessOptionClicked] = useState(false);
  const [authTypeData, setAuthTypeData] = useState(authenticationTypesData);
  const [stepperModalContent, setStepperModalContent] = useState<any>({ title: '', footer: '', body: '' });
  const [childContentItemClicked, setChildContentItemClick] = useState(false);
  const [idamOrIamCheckboxChecked, setIdamOrIamCheckboxChecked] = useState(false);
  const [userNameValue, setUserNameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [iamOrOtherUserNameValue, setIamOrOtherUserNameValue] = useState('');
  const [iamOrOtherPasswordValue, setIamOrOtherPasswordValue] = useState('');
  const createAssessmentWork$Ref = useRef<Subscription>();
  const [isAssetAssessmentScanning, setIsAssetAssessmentScanning] = useState(false);
  const [saveProgressBtnLoading, setSaveProgressBtnLoading] = useState(false);
  const [showAllPageLoading, setShowAllPageLoading] = useState(false);
  const [curProductInfo, setCurProductInfo] = useState<any>();
  const [stepperReadonly, setStepperReadonly] = useState(true);

  const [startScanBtnDisabled, setStartScanBtnDisabled] = useState(false);

  const latestTableDataRef = useRef<any[]>([]);

  const textAreaRef = useRef<any>();

  const location = useLocation();
  const navigate = useNavigate();

  const [accoringActiveKeys, setAccoringActiveKeys] = React.useState<string[]>([]);

  const [showStep3UnreachableLabel, setShowStep3UnreachableLabel] = useState(false);

  useEffect(() => {
    const _res = authTypeData?.filter((at) => at.clicked)[0];
    setFinalAuthTypeUsed(_res?.value);
  }, [authTypeData]);

  useEffect(() => {
    const _res = authTypeData?.filter((at) => at?.clicked)[0];
    if (['idamOrIam', 'iamOrOther', 'unsure'].includes(_res?.value)) {
      setFinalClickedAccessRequirements('mustLog');
    } else {
      setFinalClickedAccessRequirements('allCanAccess');
    }
  }, [authTypeData]);

  useEffect(() => {
    if (location && location.state && location.state?.curProductInfo) {
      setCurProductInfo(location.state?.curProductInfo);
    } else {
      if (getProductInfoCookie()) {
        const productInfo = safelyParseJSON(sessionStorage.getItem('productId') as string);
        setCurProductInfo(productInfo);
      }
    }
  }, [location]);

  const [showScanInprogressModal, setShowScanInprogressModal] = useState(false);

  const [finalClickedAccessRequirements, setFinalClickedAccessRequirements] = useState('');
  const [finalAuthTypeUsed, setFinalAuthTypeUsed] = useState('');

  const [showStep1Table, setShowStep1Table] = useState(true);

  const v2SaveAutoScanUrls$Ref = useRef<Subscription>();
  const updateAssetCredential$Ref = useRef<Subscription>();

  const onStepperChange = (i: number) => {
    setActiveIndex(i);
  };

  const [textAreaError, setTextAreaError] = useState<any>();

  const onPageUrlInputChange = (value: string, event: any) => {
    let temp: string = '';
    if (value.includes(',')) {
      const _urls = value.split(',');
      temp = _urls.map((url) => getClickableLink(url)).join('\n');
    } else if (value.includes('\n')) {
      const _urls = value.split('\n').filter((itm) => itm !== 'https://');
      temp = _urls.map((url) => getClickableLink(url)).join('\n');
    } else if (value === 'https://') {
      temp = '';
    } else {
      temp = getClickableLink(value);
    }

    setPageUrlInputVal(temp);

    const checkedData = temp.split('\n');
    const validateRes = validateUrlForAutoScan(
      checkedData,
      latestTableDataRef?.current?.map((xx) => xx?.url),
    );
    if (validateRes) {
      setTextAreaError({ ...validateRes, errorFlag: true });
    } else {
      setTextAreaError({ errorFlag: false, errMsg: '' });
    }

    textAreaRef.current.style.height = 'auto';
    setTimeout(() => {
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }, 0);
  };

  const addUrlBtnCLickHandler = () => {
    const _data = pageUrlInputVal.split('\n').map((url, idx) => ({
      id: idx + 1,
      pageTitle: '',
      url,
      scanStatus: 'Pending',
      action: 'Delete',
    }));

    const _res = [..._data, ...latestTableDataRef?.current]
      .map((ele, idx) => ({ ...ele, id: idx + 1 }))
      .filter((ele) => ele.url);

    latestTableDataRef.current = _res;

    setCurrentPage(1);

    const _temp = latestTableDataRef.current.map((ele, idx) => ({ ...ele, id: idx + 1 })).slice(0, 10);
    setTableData(_temp);

    setShowStep1Table(true);
    setPageUrlInputVal('');

    textAreaRef.current.style.height = '20px';
    textAreaRef.current.style['min-height'] = '20px';
  };

  const renderToPageTitleCell = (row: any, field: string) => {
    const pgCnt = activeIndex === 2 ? 10 : 10;
    return (
      <div>
        <Input
          type="text"
          hideTitleOnInput={true}
          value={row.pageTitle}
          // defaultValue={row.pageTitle}
          onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
            latestTableDataRef.current = latestTableDataRef?.current.map((ele) =>
              ele.url === row.url ? { ...ele, pageTitle: value } : { ...ele, pageTitle: ele.pageTitle },
            );
            setTableData(
              latestTableDataRef?.current
                .slice((currentPage - 1) * pgCnt, currentPage * pgCnt)
                .map((ele) => (ele.url === row.url ? { ...ele, pageTitle: value } : ele)),
            );
          }}
        />
      </div>
    );
  };

  const onActionDeleteClick = (row: any) => {
    const pgCnt = activeIndex === 2 ? 10 : 10;
    latestTableDataRef.current = latestTableDataRef?.current.filter((item) => item.url !== row.url);
    const curRestData = tableData.filter((item) => item.url !== row.url);

    if (curRestData.length === 0) {
      setCurrentPage((prev) => prev - 1);
      setTableData(latestTableDataRef.current.slice((currentPage - 2) * pgCnt, currentPage * pgCnt));
    } else {
      setTableData(latestTableDataRef.current.slice((currentPage - 1) * pgCnt, currentPage * pgCnt));
    }
  };

  const onDeleteIconKeyDown = (event: React.KeyboardEvent, row: any) => {
    const key = event.key.toLowerCase();
    if (key === 'enter') {
      onActionDeleteClick(row);
    }
  };
  const renderToActionCell = (row: any, field: string) => {
    return (
      <span
        tabIndex={0}
        className="Appkit4-icon icon-delete-outline"
        aria-label="Delete"
        onClick={() => {
          onActionDeleteClick(row);
        }}
        onKeyDown={(event: React.KeyboardEvent) => onDeleteIconKeyDown(event, row)}
      ></span>
    );
  };
  const renderToScanStatusCell = (row: any, field: string) => {
    return (
      <div className="autoPage-pagesToScan-scanStaus-container">
        <span className="Appkit4-icon icon-hb-full-fill autoPage-content-stepperContent-tablePart-table-pendingColor"></span>
        <span>Pending</span>
      </div>
    );
  };

  const renderToUrlCell = (row: any, field: string) => {
    return (
      <a href={row.url} target="_blank" rel="noopener noreferrer">
        {row.url}
      </a>
    );
  };

  const renderToStep3ScanStatusCell = (row: any, field: string) => {
    const { scanStatus } = row;

    const getStatusColor = () => {
      if (scanStatus >= 3) return { color: 'autoPage-completeColor', text: 'Completed' };
      if (scanStatus >= 2 || isAssetAssessmentScanning) return { color: 'autoPage-scaningColor', text: 'Scanning...' };
      if (scanStatus >= 1) return { color: 'autoPage-pendingColor', text: 'Pending' };
      if (scanStatus < 0) return { color: 'autoPage-errorColor', text: 'Error' };
      return { color: 'autoPage-pendingColor', text: 'Pending' };
    };

    return (
      <div className="autoPage-table-ScanStatus">
        <span className={`Appkit4-icon icon-hb-full-fill ${getStatusColor()?.color}`}></span>
        <span>{getStatusColor()?.text}</span>
      </div>
    );
  };

  const pagesTableHeaders = [
    {
      name: 'Page title',
      value: 'pageTitle',
      field: 'pageTitle',
      renderCell: renderToPageTitleCell,
    },
    {
      name: 'URL',
      value: 'url',
      field: 'url',
      renderCell: renderToUrlCell,
    },
    {
      name: 'Scan status',
      value: 'scanStatus',
      field: 'scanStatus',
      // renderCell: renderToScanStatusCell,
      renderCell: renderToStep3ScanStatusCell,
    },
    {
      name: 'Action',
      value: 'action',
      field: 'action',
      renderCell: renderToActionCell,
    },
  ];

  // if use originTableData，after call addUrlBtnCLickHandler, originTableData won't  be the same with  originTableData in addUrlBtnCLickHandler
  const onPageChange = (page: number, pgCnt: number) => {
    let temp = page <= 0 ? 1 : page;
    setCurrentPage(temp);
    setTableData(latestTableDataRef.current.slice((page - 1) * pgCnt, page * pgCnt));
  };

  const getItemIcon = (value: string) => {
    if (value === 'idamOrIam') {
      return 'icon-shield-half-outline';
    }
    if (value === 'iamOrOther') {
      return 'icon-shield-outline';
    }
    if (value === 'unsure') {
      return 'icon-help-question-outline';
    }
  };

  const childContentItemClick = (item: { [x: string]: any }, event: React.MouseEvent) => {
    setFinalAuthTypeUsed(item?.value);
    setAuthTypeData((prevs) => prevs.map((prev) => ({ ...prev, clicked: prev?.value === item?.value })));
    let param: any = {};
    switch (item?.value) {
      case 'idamOrIam':
        param = {
          imgSrc: LoggedOutSvg,
          h2Ele: 'Additional steps are needed.',
          descEle:
            "To scan products using PwC IdAM or IAM's federated PwC identity service, additional steps are required to grant us access.",
        };
        break;
      case 'iamOrOther':
        param = {
          imgSrc: UpdatedPasswordUpdatedSvg,
          h2Ele: 'Access to your user credentials is needed.',
          descEle:
            'To scan products using IAM or other authorization services, we need access to one of your admin usernames and passwords.',
        };
        break;
      case 'unsure':
        param = {
          imgSrc: ConsultingCyberSecuritySvg,
          h2Ele: 'Reach out to your development team.',
          descEle:
            'If unsure about the type of authentication used on your product, please reach out to your development team for clarification. ',
        };
        break;
      default:
        param = {};
    }

    const modalContent = {
      title: '',
      body: (
        <div className="stepperModal-authTypeModal">
          <div className="stepperModal-authTypeModal-imgPart">
            <img src={param.imgSrc} alt="decorative" />
          </div>
          <div className="stepperModal-authTypeModal-title">
            <h2>{param.h2Ele}</h2>
          </div>
          <div className="stepperModal-authTypeModal-desc">
            <span>{param.descEle}</span>
          </div>
        </div>
      ),
      footer: (
        <Button kind="primary" onClick={() => setShowStepperModal(false)}>
          Understood
        </Button>
      ),
    };
    setStepperModalContent(modalContent);

    setChildContentItemClick(true);

    const targetHtml = (event.target as HTMLElement).closest('li');
    if (targetHtml?.classList.contains('expanded')) {
      setShowStepperModal(false);
    } else {
      setShowStepperModal(true);
    }
  };

  const getExpandPart = (item: any) => {
    let res: any;
    switch (item?.value) {
      case 'idamOrIam':
        res = (
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam">
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms">
              {idamOrIamExpandData?.map((item, idx) => {
                let eles: any;
                if (item?.value === 'addAccounts') {
                  eles = (
                    <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms-single">
                      <span>{idx + 1}.</span>
                      <span>{item.label}</span>
                      <ul>
                        {item.links.map((link, index) => (
                          <li key={`${index}-${link}`}>
                            <a href="#" target="_blank" rel="noopener noreferrer">
                              {link}
                            </a>
                            <span className="Appkit4-icon icon-link-outline"></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                } else {
                  eles = (
                    <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms-single">
                      <span>{idx + 1}.</span>
                      <span>{item.label}</span>
                    </div>
                  );
                }
                return <div key={`${idx}-${item?.value}`}>{eles}</div>;
              })}
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-checkbox">
              <Checkbox
                checked={idamOrIamCheckboxChecked}
                onChange={(checked: boolean, event: React.SyntheticEvent) => {
                  setIdamOrIamCheckboxChecked(checked);
                  event.stopPropagation();
                }}
              >
                I have added both accounts and granted admin permissions.
              </Checkbox>
            </div>
          </div>
        );
        break;
      case 'iamOrOther':
        res = (
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther">
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther-desc">
              <span>Please provide one of your admin user credentials with full product access.</span>
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther-usrInfo">
              <Input
                value={iamOrOtherUserNameValue}
                onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
                  setIamOrOtherUserNameValue(value);
                }}
                type="text"
                title="Username"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
              />
              <Input
                value={iamOrOtherPasswordValue}
                type="password"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) =>
                  setIamOrOtherPasswordValue(value)
                }
                title="Password"
              />
            </div>
          </div>
        );
        break;
      case 'unsure':
        res = (
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-unsure">
            <div>
              <span>1.</span>
              <span>Ask your development team the type of user authentication used in your product.</span>
            </div>
            <div>
              <span>2.</span>
              <span>Return to resume your process by selecting the correct authentication type.</span>
            </div>
          </div>
        );
        break;
      default:
        res = <></>;
    }

    return res;
  };

  const getAccessRequirementsContent = () => {
    let res: any;
    if (finalClickedAccessRequirements === 'mustLog') {
      res = {
        icon: <span className="Appkit4-icon icon-lockclosed-locked-outline autoPage-accessRequirements-icon"></span>,
        title: 'Users must log in or sign up to access my product. ',
        desc: 'User access in your product is restricted. Most PwC products, except for global or public sites, fall into this category.',
      };
    } else if (finalClickedAccessRequirements === 'allCanAccess') {
      res = {
        icon: <span className="Appkit4-icon icon-lockopen-unlock-outline autoPage-accessRequirements-icon"></span>,
        title: 'Anyone can access my product without log in or sign up.',
        desc: 'User access is not restricted in your product. Even external people can access.',
      };
    }
    return (
      <>
        {res?.icon}
        <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-content-right">
          <h2>{res?.title}</h2>
          <span>
            User access in your product is restricted. Most PwC products, except for global or public sites, fall into
            this category.
          </span>
        </div>
      </>
    );
  };

  const getAccessRequirementsAuthTypePartContent = () => {
    let res: any;
    if (finalAuthTypeUsed === 'idamOrIam') {
      res = {
        icon: <span className="Appkit4-icon icon-shield-half-outline autoPage-accessRequirements-icon"></span>,
        title: "PwC IdAM or IAM's federated PwC Identity service.",
        desc: 'Your product uses access management services provided by PwC. ',
      };
    } else if (finalAuthTypeUsed === 'iamOrOther') {
      res = {
        icon: <span className="Appkit4-icon icon-shield-outline autoPage-accessRequirements-icon"></span>,
        title: 'IAM or other authorization services. ',
        desc: 'Your product uses access management services provided by external companies or third-party services. ',
      };
    }

    return (
      <>
        {res?.icon}
        <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-content-right">
          <h2>{res?.title}</h2>
          <span>{res?.desc}</span>
        </div>
      </>
    );
  };

  const getShowingParam = () => {
    const pgCnt = activeIndex === 2 ? 10 : 10;
    let start = pgCnt * currentPage - (pgCnt - 1);
    let end =
      latestTableDataRef?.current.length < currentPage * pgCnt
        ? latestTableDataRef?.current.length
        : currentPage * pgCnt;
    return {
      start,
      end,
    };
  };

  useEffect(() => {
    let getProductInfoSubscription: any;
    if (curProductInfo) {
      getProductInfoSubscription = zip(
        productService.getProductInfo(curProductInfo?.appId),
        assetService.getAssetUrls(curProductInfo?.id),
      )
        .pipe(
          take(1),
          map((res) => ({ ...res[0], urls: res[1]?.data })),
        )
        .subscribe((res: any) => {
          const { ftuxStatus, authType, authUser, authPwd, urls } = res;
          if (ftuxStatus === 2) {
            setActiveIndex(0);
            latestTableDataRef.current = urls.map((url: any, idx: number) => ({
              ...url,
              id: idx + 1,
              pageTitle: url?.nickName,
              url: url?.siteUrl,
              scanStatus: url?.scanStatus,
              action: 'Delete',
            }));
            const _temp = latestTableDataRef.current.map((ele, idx) => ({ ...ele, id: idx + 1 })).slice(0, 10);
            setTableData(_temp);
            setShowStep1Table(true);
          }

          if (ftuxStatus === 4) {
            setActiveIndex(2);
            latestTableDataRef.current = urls.map((url: any, idx: number) => ({
              ...url,
              id: idx + 1,
              pageTitle: url?.nickName,
              url: url?.siteUrl,
              scanStatus: url?.scanStatus,
              action: 'Delete',
            }));

            const _temp = latestTableDataRef.current.map((ele, idx) => ({ ...ele, id: idx + 1 })).slice(0, 10);
            setTableData(_temp);

            if (authType === 'idam') {
              setFinalClickedAccessRequirements('mustLog');
              setFinalAuthTypeUsed('idamOrIam');
            }
            if (authType === 'iam') {
              setFinalClickedAccessRequirements('mustLog');
              // setFinalClickedAccessRequirements('allCanAccess');
              setFinalAuthTypeUsed('iamOrOther');
            }
            if (authType === 'unsure') {
              setFinalClickedAccessRequirements('mustLog');
            }
            if (authType === 'public') {
              setAllCanAccessOptionClicked(true);
              setFinalClickedAccessRequirements('allCanAccess');
            }
          }

          if (ftuxStatus === 3) {
            latestTableDataRef.current = urls.map((url: any, idx: number) => ({
              ...url,
              id: idx + 1,
              pageTitle: url?.nickName,
              url: url?.siteUrl,
              scanStatus: url?.scanStatus,
              action: 'Delete',
            }));

            const _temp = latestTableDataRef.current.map((ele, idx) => ({ ...ele, id: idx + 1 })).slice(0, 10);
            setTableData(_temp);

            setActiveIndex(1);
            let temp: any;
            if (authType === 'idam') {
              setMustLogOptionClicked(true);
              temp = authTypeData.map((d) => ({ ...d, clicked: d.value === 'idamOrIam' }));
              setIdamOrIamCheckboxChecked(true);
            } else if (authType === 'iam') {
              setMustLogOptionClicked(true);
              temp = authTypeData.map((d) => ({ ...d, clicked: d.value === 'iamOrOther' }));
              setIamOrOtherUserNameValue(authUser);
            } else if (authType === 'unsure') {
              setMustLogOptionClicked(true);
              temp = authTypeData.map((d) => ({ ...d, clicked: d.value === 'unsure' }));
            } else if (authType === 'public') {
              setAllCanAccessOptionClicked(true);
            }
            setAuthTypeData(temp);
          }

          setShowAllPageLoading(false);
        });
    }
    return () => {
      getProductInfoSubscription?.unsubscribe();
    };
  }, [curProductInfo]);

  useEffect(() => {
    return () => {
      createAssessmentWork$Ref.current?.unsubscribe();
      v2SaveAutoScanUrls$Ref.current?.unsubscribe();
      updateAssetCredential$Ref.current?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (textAreaRef && textAreaRef?.current) {
      const textarea = textAreaRef?.current;
      if (textarea) {
        textarea.style.height = '20px';
        textarea.style['min-height'] = '20px';
      }
    }
  }, [textAreaRef]);

  const renderToStep3ActionCell = (row: any, field: string) => {
    return (
      <span
        className="Appkit4-icon icon-delete-outline"
        onClick={() => {
          onActionDeleteClick(row);
        }}
      ></span>
    );
  };

  const step3PagesTableHeaders = [
    {
      name: 'Page title',
      value: 'pageTitle',
      field: 'pageTitle',
      renderCell: renderToPageTitleCell,
    },
    {
      name: 'URL',
      value: 'url',
      field: 'url',
      renderCell: renderToUrlCell,
    },
    {
      name: 'Scan status',
      value: 'scanStatus',
      field: 'scanStatus',
      renderCell: renderToStep3ScanStatusCell,
    },
    // {
    //   name: 'Action',
    //   value: 'action',
    //   field: 'action',
    //   renderCell: renderToStep3ActionCell,
    // },
  ];

  const accordingData = [
    {
      value: 'userMustLogPart',
      header: (
        <div
          className={`autoPage-content-stepperContent-accessOptionPart-option ${mustLogOptionClicked ? 'clickedStyle' : ''}`}
          // onClick={() => {
          //   setMustLogOptionClicked(true);
          //   setAllCanAccessOptionClicked(false);
          //   setAuthTypeData(authenticationTypesData);
          //   setFinalClickedAccessRequirements('mustLog');
          // }}
        >
          <div className="autoPage-content-stepperContent-accessOptionPart-option-icon">
            <span className="Appkit4-icon icon-lockclosed-locked-outline autoPage-accessRequirements-icon"></span>
          </div>

          <div className="autoPage-content-stepperContent-accessOptionPart-option-texts">
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-title">
              <h2>Users must log in or sign up to access my product. </h2>
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-desc">
              <span>
                User access in your product is restricted. Most PwC products, except for global or public sites, fall
                into this category.
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: 'anyoneCanAccessPart',
      header: (
        <div
          className={`autoPage-content-stepperContent-accessOptionPart-option allCanAccess ${allCanAccessOptionClicked ? 'clickedStyle' : ''}`}
          // onClick={() => {
          //   setMustLogOptionClicked(false);
          //   setAllCanAccessOptionClicked(true);
          //   setAuthTypeData(authenticationTypesData);
          //   setFinalClickedAccessRequirements('allCanAccess');
          // }}
          onKeyDown={(event: React.KeyboardEvent) => {
            const key = event.key.toLowerCase();
            if (key === 'enter') {
              setMustLogOptionClicked(false);
              setAllCanAccessOptionClicked(true);
              setAuthTypeData(authenticationTypesData);
              setFinalClickedAccessRequirements('allCanAccess');
            }
          }}
        >
          <div className="autoPage-content-stepperContent-accessOptionPart-option-icon">
            <span className="Appkit4-icon icon-lockopen-unlock-outline autoPage-accessRequirements-icon"></span>
          </div>
          <div className="autoPage-content-stepperContent-accessOptionPart-option-texts">
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-title">
              <h2>Anyone can access my product without log in or sign up.</h2>
            </div>
            <div className="autoPage-content-stepperContent-accessOptionPart-option-texts-desc">
              <span>User access is not restricted in your product. Even external people can access.</span>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const onClickAccordion = (activeKeys: string[]) => {
    if (activeKeys[0] === '1') {
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setAuthTypeData(authenticationTypesData);
      setFinalClickedAccessRequirements('mustLog');
    }
    if (activeKeys[0] === '2') {
      setMustLogOptionClicked(false);
      setAllCanAccessOptionClicked(true);
      setAuthTypeData(authenticationTypesData);
      setFinalClickedAccessRequirements('allCanAccess');
    }
    setChildAccoringActiveKeys(['']);
    setAccoringActiveKeys(activeKeys);
  };

  const [childAccoringActiveKeys, setChildAccoringActiveKeys] = React.useState<string[]>([]);
  const onChildAccordingClickAccordion = (activeKeys: string[], event: React.MouseEvent) => {
    setChildAccoringActiveKeys(activeKeys);
    const index = Number(activeKeys[0]);
    const item = authTypeData[index];
    childContentItemClick(item, event);
  };
  const childAccordingHeaders = (item: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-icon">
          <span className={`Appkit4-icon ${getItemIcon(item?.value)} autoPage-accessRequirements-icon`}></span>
        </div>
        <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-texts">
          <h2 className="autoPage-content-stepperContent-accessOptionPart-child-content-item-texts-title">
            {item.title}
          </h2>
          <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-texts-desc">
            <span>{item.desc}</span>
          </div>
        </div>
      </div>
    );
  };

  const getUnReachableContainerCls = () => {
    if (showStep3UnreachableLabel) {
      if (latestTableDataRef?.current.length >= 10) {
        return 'autoPage-unReachable-container-twoChild';
      } else {
        return 'autoPage-unReachable-label-flexRevert';
      }
    }
    return '';
  };

  const processStepContent = useMemo(() => {
    if (activeIndex === 0) {
      return (
        <>
          <div className="autoPage-content-stepperContent">
            <div className="autoPage-content-stepperContent-headers">
              <h2>Pages to scan</h2>
              <span>Required Fields</span>
            </div>

            <span className="autoPage-content-stepperContent-desc">
              Add the most representative pages of your product you would like to check for compliance.{' '}
            </span>
            <div className="ap-page-section-desc ap-mt-spacing-3 notification-panel">
              <span className="Appkit4-icon icon-information-outline"></span>
              <div>If you need to scan external pages (websites or URLs outside of PwC's internal network), please start by submitting a <a href="https://forms.office.com/r/TGReUVqfwc" target="_blank">whitelisting request</a>. </div>
            </div>
            <div className="autoPage-content-stepperContent-enterPageUrlPart">
              <div className="autoPage-content-stepperContent-enterPageUrlPart-inputPart">
                <TextArea
                  title={'Enter page URL (one per line or comma separated)'}
                  required={true}
                  inputRef={textAreaRef}
                  value={pageUrlInputVal}
                  onChange={onPageUrlInputChange}
                  error={textAreaError?.errorFlag}
                  errorNode={<span>{textAreaError?.errMsg}</span>}
                  autosize={true}
                  onBlur={() => {
                    if (!pageUrlInputVal) {
                      textAreaRef.current.style.height = '20px';
                      textAreaRef.current.style['min-height'] = '20px';
                    }
                  }}
                ></TextArea>
              </div>
              <div className="autoPage-content-stepperContent-enterPageUrlPart-addBtnPart">
                <Button
                  disabled={!pageUrlInputVal || textAreaError?.errorFlag}
                  kind="secondary"
                  onClick={addUrlBtnCLickHandler}
                >
                  Add URL
                </Button>
              </div>
            </div>

            {showStep1Table ? (
              <div className="autoPage-content-stepperContent-tablePart">
                {latestTableDataRef?.current.length >= 10 ? (
                  <span className="autoPage-content-stepperContent-tablePart-showingPart">
                    Showing {getShowingParam().start}-{getShowingParam().end} of {latestTableDataRef?.current.length}
                  </span>
                ) : null}

                {latestTableDataRef?.current.length === 0 ? null : (
                  <div className="autoPage-content-stepperContent-tablePart-table">
                    <Table originalData={tableData} hasTitle striped condensed>
                      {pagesTableHeaders.map((header: any, idx: number) => (
                        <Column
                          key={`${idx}-${header?.value}`}
                          field={header.field}
                          sortKey={header.sortKey}
                          sortFunc1={header.sortFunc1}
                          sortFunc2={header.sortFunc2}
                          renderCell={header.renderCell}
                        >
                          {header.name}
                        </Column>
                      ))}
                    </Table>
                  </div>
                )}

                {latestTableDataRef?.current.length >= 10 ? (
                  <div className="autoPage-content-stepperContent-tablePart-pagnation">
                    <Pagination
                      current={currentPage}
                      total={Math.ceil(latestTableDataRef?.current.length / 10)}
                      onPageChange={(page) => onPageChange(page, 10)}
                      toPreviousPage=""
                      toNextPage=""
                    ></Pagination>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </>
      );
    }
    if (activeIndex === 1) {
      return (
        <>
          <div className="autoPage-content-stepperContent">
            <div className="autoPage-content-stepperContent-headers">
              <h2>Access requirements</h2>
              <span>Required Fields</span>
            </div>

            <span className="autoPage-content-stepperContent-desc">
              Grant access to our scanner based on your product's access requirements.
            </span>
            <div className="autoPage-content-stepperContent-accessOptionPart">
              <Accordion
                multiple={false}
                onClick={onClickAccordion}
                activeKeys={accoringActiveKeys}
                showExpandIcon={false}
              >
                <AccordionItem templateHeader={() => accordingData[0].header} itemKey={'1'}>
                  <div
                    className={`autoPage-content-stepperContent-accessOptionPart-child ${mustLogOptionClicked ? 'show' : ''}`}
                  >
                    <div className="autoPage-content-stepperContent-accessOptionPart-child-title">
                      <h3>Authentication type used</h3>
                    </div>
                    <div className="autoPage-content-stepperContent-accessOptionPart-child-content">
                      <Accordion
                        multiple={false}
                        onClick={(activeKeys: string[], event: React.MouseEvent<HTMLElement>) => {
                          onChildAccordingClickAccordion(activeKeys, event);
                        }}
                        activeKeys={childAccoringActiveKeys}
                        showExpandIcon={false}
                      >
                        {authTypeData?.map((item: any, idx: number) => (
                          <AccordionItem
                            key={`${idx}-${item?.value}`}
                            templateHeader={() => childAccordingHeaders(item)}
                            itemKey={`${idx}`}
                            className={`${item.clicked ? 'content-clicked' : ''}`}
                          >
                            <div style={{ display: 'flex', width: '100%' }}>
                              <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-icon">
                                <span className={`Appkit4-icon autoPage-accessRequirements-icon`}></span>
                              </div>
                              <div
                                className={`autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart ${item.clicked ? 'show' : ''}`}
                              >
                                <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-title">
                                  <span>Before continuing</span>
                                </div>
                                {getExpandPart(item)}
                              </div>
                            </div>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </AccordionItem>
                <AccordionItem
                  templateHeader={() => accordingData[1].header}
                  itemKey={'2'}
                  className="as-accordingItem2"
                ></AccordionItem>
              </Accordion>
            </div>
            <div className="autoPage-content-stepperContent-needHelp">
              <span>Need help?</span>
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-qJtLN_BonZFrxiPNTnvCsRUNFUzWUwySEdIUjJWN1RXNkhENjYwUjhNNC4u"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us.
              </a>
            </div>
          </div>
        </>
      );
    }

    if (activeIndex === 2) {
      return (
        <div className="autoPage-content-stepperContent">
          <div className="autoPage-content-stepperContent-headers">
            <h2>Review and scan</h2>
          </div>
          <span className="autoPage-content-stepperContent-desc">
            Review the information provided. You can go back and make changes before proceeding with the scan.
          </span>

          <div className="autoPage-content-stepperContent-reviewPart">
            <div className="autoPage-content-stepperContent-reviewPart-pagesToScan">
              <div className="autoPage-content-stepperContent-reviewPart-pagesToScan-title">
                <span>Pages to scan</span>
              </div>
              <div className="autoPage-content-stepperContent-reviewPart-pagesToScan-tablePart">
                <div className={getUnReachableContainerCls()}>
                  {latestTableDataRef?.current.length >= 10 ? (
                    <span className="autoPage-content-stepperContent-reviewPart-pagesToScan-tablePart-showingPart">
                      Showing {getShowingParam().start}-{getShowingParam().end} of {latestTableDataRef?.current.length}
                    </span>
                  ) : null}
                  {showStep3UnreachableLabel ? (
                    <div className="unreachablepart-showingPart">
                      <span className={`Appkit4-icon icon-hb-full-fill autoPage-unReachable-label-erorColor`}></span>
                      <span className="unReachable-label-spanLast">Unreachable URL.</span>
                    </div>
                  ) : null}
                </div>

                <div className="autoPage-content-stepperContent-reviewPart-pagesToScan-tablePart-table">
                  <Table originalData={tableData} hasTitle striped condensed>
                    {step3PagesTableHeaders.map((header: any, idx: number) => (
                      <Column key={`${idx}-${header?.value}`} field={header.field} renderCell={header.renderCell}>
                        {header.name}
                      </Column>
                    ))}
                  </Table>
                </div>
                {latestTableDataRef?.current.length >= 10 ? (
                  <div className="autoPage-content-stepperContent-reviewPart-pagesToScan-tablePart-pagnation">
                    <Pagination
                      current={currentPage}
                      total={Math.ceil(latestTableDataRef?.current.length / 10)}
                      onPageChange={(page) => onPageChange(page, 10)}
                      toPreviousPage=""
                      toNextPage=""
                    ></Pagination>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="autoPage-content-stepperContent-reviewPart-accessRequirements">
              <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-title">
                <span>Access requirements</span>
              </div>
              <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-content">
                {getAccessRequirementsContent()}
              </div>
              {allCanAccessOptionClicked ? null : (
                <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-authTypePart">
                  <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-authTypePart-title">
                    <span>Authentication type</span>
                  </div>
                  <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-authTypePart-content">
                    {getAccessRequirementsAuthTypePartContent()}
                  </div>
                  {finalAuthTypeUsed && finalAuthTypeUsed === 'iamOrOther' && (
                    <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-adminPart">
                      <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-adminPart-title">
                        <span>Admin user credentials</span>
                      </div>
                      <div className="autoPage-content-stepperContent-reviewPart-accessRequirements-adminPart-content">
                        <Input
                          value={iamOrOtherUserNameValue}
                          onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
                            event?.stopPropagation();
                            setUserNameValue(value);
                          }}
                          type="text"
                          title="Username"
                          disabled={true}
                        />
                        <Input
                          value={iamOrOtherPasswordValue}
                          onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
                            event?.stopPropagation();
                            setPasswordValue(value);
                          }}
                          type="password"
                          title="Password"
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }, [
    activeIndex,
    mustLogOptionClicked,
    allCanAccessOptionClicked,
    authTypeData,
    idamOrIamCheckboxChecked,
    // origin,
    pageUrlInputVal,
    showStep1Table,
    tableData,
    latestTableDataRef,
    userNameValue,
    passwordValue,
    iamOrOtherPasswordValue,
    iamOrOtherUserNameValue,
    textAreaError,
    accoringActiveKeys,
    childAccoringActiveKeys,
    showStep3UnreachableLabel,
  ]);

  const getCredentialApiParam = () => {
    if (finalClickedAccessRequirements === 'allCanAccess') {
      return {
        id: curProductInfo?.id!,
        authType: 'public',
      };
    }
    if (finalClickedAccessRequirements === 'mustLog') {
      if (finalAuthTypeUsed === 'idamOrIam') {
        return {
          id: curProductInfo?.id!,
          authType: 'idam',
        };
      }
      if (finalAuthTypeUsed === 'iamOrOther') {
        return {
          id: curProductInfo?.id!,
          authType: 'iam',
          authUser: iamOrOtherUserNameValue,
          authPass: iamOrOtherPasswordValue,
        };
      }
      if (finalAuthTypeUsed === 'unsure') {
        return {
          id: curProductInfo?.id!,
          authType: 'unsure',
        };
      }
    }
  };

  const saveProgressClickHandler = async (originFrom: string) => {
    // pages to scan step
    let statusCode = 0;
    if (originFrom !== 'next') {
      setSaveProgressBtnLoading(true);
    }

    if (activeIndex === 0) {
      statusCode = 2;
      const param = {
        productId: String(curProductInfo?.id),
        urls: latestTableDataRef?.current.map((data) => ({
          siteUrl: data?.url,
          nickName: data?.pageTitle,
        })),
      };
      // save the urls info
      v2SaveAutoScanUrls$Ref.current = productService
        .v2SaveAutoScanUrls(param)
        .pipe(take(1))
        .subscribe((res) => {});
    } else if (activeIndex === 1) {
      // access requirements
      statusCode = 3;
      updateAssetCredential$Ref.current = assetService
        .updateAssetCredential(getCredentialApiParam())
        .pipe(take(1))
        .subscribe((res) => {});
    } else if (activeIndex === 2) {
      statusCode = 4;
      updateAssetCredential$Ref.current = assetService
        .updateAssetCredential(getCredentialApiParam())
        .pipe(take(1))
        .subscribe((res) => {});
    }

    const res = await axios.get(`/product/info/setFtuxStatus?productId=${curProductInfo?.id}&status=${statusCode}`);
    if (res.status === 1) {
      if (originFrom !== 'next') {
        setSaveProgressBtnLoading(false);
        toaster.notify(<Notification title="" message="Progress saved successfully." status="success" />, {
          position: 'bottomLeft',
          duration: 2000,
          onClose: () => {},
        });
      }
    }
  };

  const startScanClick = () => {
    setShowStep3UnreachableLabel(false);

    setStartScanBtnDisabled(true);
    setIsAssetAssessmentScanning(true);
    setCloseScanModalMethod && setCloseScanModalMethod(false);
    setCloseScanModalStatus && setCloseScanModalStatus(false);
    setTableData((prevs) => prevs.map((prev) => ({ ...prev, scanStatus: 'Scanning...' })));

    latestTableDataRef.current = [...latestTableDataRef.current?.map((ele) => ({ ...ele, scanStatus: 'Pending...' }))];
    setShowScanInprogressModal(true);
    setStepperReadonly(false);

    const param = {
      productId: String(curProductInfo?.id),
      urls: latestTableDataRef?.current.map((data) => ({
        siteUrl: data?.url,
        nickName: data?.pageTitle,
      })),
    };

    createAssessmentWork$Ref.current = productService
      .v2SaveAutoScanUrls(param)
      .pipe(
        take(1),
        switchMap((res) => {
          return assetService.updateAssetCredential(getCredentialApiParam());
        }),
        take(1),
        concatMap((updateAssetCredentialRes: any) => {
          if (updateAssetCredentialRes && updateAssetCredentialRes.success) {
            const input = {
              productId: curProductInfo?.id,
              assessmentId: null,
            };
            return assetService.createAssessmentWork(curProductInfo?.id, null).pipe(take(1));
          }
          return of(null);
        }),
      )
      .subscribe((createAssessmentWorkRes: any) => {
        if (createAssessmentWorkRes && createAssessmentWorkRes.status === 1) {
        } else {
          console.error('create assessment failed');
        }
      });
  };

  const getStep1NextBtndisabled = () => {
    if (allCanAccessOptionClicked) return !allCanAccessOptionClicked;
    if (mustLogOptionClicked) {
      const authTypeClickedEle = authTypeData?.filter((itm) => itm.clicked)[0];
      if (authTypeClickedEle?.value === 'idamOrIam') {
        return !idamOrIamCheckboxChecked;
      }
      if (authTypeClickedEle?.value === 'iamOrOther') {
        return !(iamOrOtherUserNameValue && iamOrOtherPasswordValue);
      }
      if (authTypeClickedEle?.value === 'unsure') {
        return true;
      }
    }
    return true;
  };

  const onStep1CancelClick = () => {
    setStepperModalContent({
      title: 'Save progress?',
      body: (
        <span>
          Please ensure that you save your progress. Exiting this process without doing so will result in the loss of
          all information provided.{' '}
        </span>
      ),
      footer: (
        <>
          <Button
            kind="text"
            onClick={() => {
              setShowStepperModal(false);
              navigate(`/v2/dwrapper/board?appId=${curProductInfo?.appId}`);
            }}
          >
            Cancel without saving
          </Button>
          <Button
            kind="primary"
            onClick={() => {
              saveProgressClickHandler('saveProgress');
              setShowStepperModal(false);
              navigate(`/v2/dwrapper/board?appId=${curProductInfo?.appId}`);
            }}
          >
            Save progress
          </Button>
        </>
      ),
    });
    setShowStepperModal(true);
  };

  const getProcessStepContentButtons = () => {
    if (activeIndex === 0) {
      return (
        <>
          <Button kind="text" onClick={onStep1CancelClick}>
            Cancel
          </Button>
          <div className="autoPage-bottom-cancelNext-stepper2Btns">
            {latestTableDataRef?.current.length > 0 && (
              <Button
                loading={saveProgressBtnLoading}
                kind="secondary"
                onClick={() => saveProgressClickHandler('saveProgress')}
              >
                Save progress
              </Button>
            )}
            <Button
              kind="primary"
              onClick={() => {
                setActiveIndex((prev) => prev + 1);
                saveProgressClickHandler('next');
              }}
              disabled={latestTableDataRef?.current.length === 0}
            >
              Next
            </Button>
          </div>
        </>
      );
    }
    if (activeIndex === 1) {
      return (
        <>
          <Button kind="text" onClick={onStep1CancelClick}>
            Cancel
          </Button>
          <div className="autoPage-bottom-cancelNext-stepper2Btns">
            <Button
              loading={saveProgressBtnLoading}
              kind="secondary"
              onClick={() => saveProgressClickHandler('saveProgress')}
            >
              Save progress
            </Button>
            <Button
              kind="secondary"
              onClick={() => {
                setActiveIndex(0);
                setShowStep1Table(true);
              }}
            >
              Previous
            </Button>
            <Button
              kind="primary"
              disabled={getStep1NextBtndisabled()}
              onClick={() => {
                saveProgressClickHandler('next');
                setActiveIndex((pre) => pre + 1);
                setCurrentPage(1);
                setTableData(latestTableDataRef?.current.slice(0, 10));
              }}
            >
              Next
            </Button>
          </div>
        </>
      );
    }
    const loadingBtn = <Button kind="primary" onClick={startScanClick} loading={true} disabled={true}>
      Start scan
    </Button>;
    if (activeIndex === 2) {
      return (
        <>
          <Button kind="text" onClick={onStep1CancelClick}>
            Cancel
          </Button>
          <div className="autoPage-bottom-cancelNext-stepper2Btns">
            <Button
              loading={saveProgressBtnLoading}
              kind="secondary"
              onClick={() => saveProgressClickHandler('saveProgress')}
            >
              Save progress
            </Button>
            <Button
              kind="secondary"
              onClick={() => {
                setActiveIndex(1);
              }}
            >
              Previous
            </Button>
            {startScanBtnDisabled? loadingBtn : <Button kind="primary" onClick={startScanClick}>
              Start scan
            </Button>}
          </div>
        </>
      );
    }
  };

  const onBreadcrumbItemClick = () => {
    navigate(`/v2/dwrapper/board?appId=${curProductInfo?.appId}`);
  };

  const setStep3AllTableData = (data: any[]) => {
    latestTableDataRef.current = [...data];
  };

  return showAllPageLoading ? (
    <LoadingComponent />
  ) : (
    <>
      <div className="ap-container autoPage">
        <div className={'autoPage-content'} tabIndex={-1}>
          <div className="row">
            <div className="autoPage-content-breadcrumbPart">
              <Breadcrumb>
                <BreadcrumbItem onClick={onBreadcrumbItemClick}>
                  <span tabIndex={0}>Dashboard</span>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span tabIndex={0}>Set up automated scan</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
          <div className="row">
            <h1 className="autoPage-content-scanLabel">Set up automated scan</h1>
          </div>

          {/* narrow screen part */}
          <div className="row autoPage-narrow">
            <div className="autoPage-content-progressbarPart">
              <Steppers
                // orientation="vertical"
                space={148}
                activeIndex={activeIndex}
                onActiveIndexChange={onStepperChange}
                className="autoPage-content-progressbarPart-steppers"
                // readonly={stepperReadonly}
              >
                {steppersData?.map((stepper: any, idx: number) => (
                  <Stepper key={`${idx}-${stepper?.value}`} label={stepper.label} status={stepper.status}></Stepper>
                ))}
              </Steppers>
            </div>
          </div>

          <div className="row autoPage-narrow">{processStepContent}</div>
          {/* narrow screen part */}
          <div className="row autoPage-normal">
            <div className="col-2">
              <div className="autoPage-content-progressbarPart">
                <Steppers
                  orientation="vertical"
                  space={148}
                  activeIndex={activeIndex}
                  onActiveIndexChange={onStepperChange}
                  className="autoPage-content-progressbarPart-steppers"
                  // readonly={stepperReadonly}
                >
                  {steppersData?.map((stepper: any, idx: number) => (
                    <Stepper key={`${idx}-${stepper?.value}`} label={stepper.label} status={stepper.status}></Stepper>
                  ))}
                </Steppers>
              </div>
            </div>
            <div className="col-10">{processStepContent}</div>
          </div>
        </div>
        <div className="row">
          <div className="autoPage-bottom">
            <div className="autoPage-bottom-cancelNext">{getProcessStepContentButtons()}</div>
            <FooterPage />
          </div>
        </div>
      </div>
      <CommonModal
        visible={showStepperModal}
        modalContent={stepperModalContent}
        setVisibleFunc={setShowStepperModal}
        className={childContentItemClicked ? 'authTypeModal' : 'stepperModal'}
        bodyStyle={{}}
      />
      <ScanModal
        curProductInfo={curProductInfo}
        footer={<></>}
        visible={showScanInprogressModal && !closeScanModalMethod}
        setVisibleFunc={setShowScanInprogressModal}
        className="scanInprogressModal"
        title="Scan in progress"
        imgSrc={ScanInProgressSvg}
        boldText="Scan in progress. "
        descText="Feel free to close this window. We’ll notify you once the scan is completed. "
        originTableData={latestTableDataRef?.current}
        isAssetAssessmentScanning={isAssetAssessmentScanning}
        setIsAssetAssessmentScanning={setIsAssetAssessmentScanning}
        origin="scanInprogress"
        setStartScanBtnDisabled={setStartScanBtnDisabled}
        setStep3AllTableData={setStep3AllTableData}
        setStep3TableData={setTableData}
        step3tableData={tableData}
        setStep3CurrentPageNumber={setCurrentPage}
        setShowStep3UnreachableLabel={setShowStep3UnreachableLabel}
      />
    </>
  );
};

export default AutoMatedScanPage;
